import React from 'react';
//import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Popover from 'react-bootstrap/Popover';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import InputGroup from 'react-bootstrap/InputGroup';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import Buscador from './Buscador';

export default class AlumnoForm extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            //...this.props.alumno,
            alummo:this.props.alumno,
            edad:0,
            escuelas:[],
            grados:[],
            sgrados:[],
            medios:[],
            promociones:[]
        }
    }
    componentDidMount() {
        this.cargarCatalogos();
    }    
    componentWillReceiveProps(newProps){
        this.setState({
            //...newProps.alumno
            alumno:newProps.alumno,
            edad:this.calcularEdad(newProps.alumno.FechaNac)
        });
    }
    calcularEdad = (fec) =>{
        let fechaDeNacimiento = new Date(fec);
        let hoy = new Date();
        return parseInt((hoy - fechaDeNacimiento) / (1000*60*60*24*365));
    }
    onChange = (e) => {
        const campo = e.target.name;
        const alu = {
            ...this.state.alumno,
            [campo] : e.target.value
        };
        this.setState({alumno:alu});
    }
    onChecked = (e) => {
        const alu = {
            ...this.state.alumno,
            [e.target.name] : e.target.checked
        };
        this.setState({alumno:alu});
    }
    matRecomendado = (mat,nom) => {
        const alu = {
            ...this.state.alumno,
            Recomendado : mat,
            NombreRecomendado : nom
        };
        this.setState({alumno:alu});
    }
    guardar = () =>{
        const MySwal = withReactContent(Swal)
        MySwal.fire({
            title: 'Confirme',
            text: "¿ Desea guardar estos datos ?",
            type: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'No',
            confirmButtonText: 'Si'
          }).then((result) => {
            if (result.value) {
                this.props.guardar(this.state.alumno);
            }
          })        
    }
    mapearAlumnos = (item) => {
        return (
            <React.Fragment>
                <td>{item.Clave}</td>
                <td>{item.Nombre}</td>
            </React.Fragment>
        )
    }
    mapearEventos = (item) => {
        return (
            <React.Fragment>
                <td>{item.Nombre}</td>
                <td>{item.Puntos}</td>
                <td>{item.Inicio}</td>
                <td>{item.Fin}</td>
                <td>{item.Comentarios}</td>
            </React.Fragment>
        )
    }
    cargarCatalogos = async() =>{
        let res = await fetch("https://www.orientews.isaerpweb.com/home/escuelas");
        const escuelas = await res.json();
        res = await fetch("https://www.orientews.isaerpweb.com/home/grados");
        const grados = await res.json();
        res = await fetch("https://www.orientews.isaerpweb.com/home/subGrados");
        const sgrados = await res.json();
        res = await fetch("https://www.orientews.isaerpweb.com/home/medios");
        const medios = await res.json();
        res = await fetch("https://www.orientews.isaerpweb.com/home/promociones");
        const promociones = await res.json();
        this.setState({ escuelas, grados, sgrados, medios, promociones });
    }
    render() {
        const estilo = { display: this.props.visible ? 'inline' : 'none' };
        const popReco = (
            <Popover id="popover-basic" title="Buscar alumno">
                <Buscador urlws={this.props.urlws + "/alumno/lista?d=" + this.props.doyang} onSelect={this.matRecomendado} tipo="alumno" mapearCampos={this.mapearAlumnos} columnas={["Clave","Nombre"]}/>
            </Popover>
        );    
        if(this.props.alumno === undefined)
            return null;
        if(Object.keys(this.props.alumno).length === 0)
            return null;
        return (
            <Container style={estilo}>
                <Form>
                    <Tabs defaultActiveKey="home">
                        <Tab eventKey="home" title="Datos personales">
                            <div>
                                <Form.Row>
                                    <Form.Group as={Col} md="2">
                                        <Form.Label>Clave</Form.Label>
                                        <Form.Control size="sm" type="text" name="Clave" value={this.state.alumno.Clave} />
                                    </Form.Group>
                                    <Form.Group as={Col} md="4">
                                        <Form.Label>Nombre</Form.Label>
                                        <Form.Control size="sm" type="text" name="Nombre" value={this.state.alumno.Nombre} onChange={this.onChange}/>
                                    </Form.Group>
                                    <Form.Group as={Col} md="2">
                                        <Form.Label>Status</Form.Label>
                                        <Form.Control size="sm" as="select" name="Activo" value={this.state.alumno.Activo} onChange={this.onChange}>
                                            <option key="1" value="1">Activo</option>
                                            <option key="2" value="2">No activo</option>
                                        </Form.Control>
                                    </Form.Group>

                                    <Form.Group as={Col} md="2">
                                        <Form.Label>Matricula MDK</Form.Label>
                                        <Form.Control size="sm" type="text" name="MatMdk" value={this.state.alumno.MatMdk} onChange={this.onChange} placeholder="Matricula MDK" />
                                    </Form.Group>
                                    <Form.Group as={Col} md="1">
                                        <Form.Label>Peso</Form.Label>
                                        <Form.Control size="sm" type="text" placeholder="Peso" name="Peso" value={this.state.alumno.Peso} onChange={this.onChange} />
                                    </Form.Group>
                                    <Form.Group as={Col} md="1">
                                        <Form.Label>Estatura</Form.Label>
                                        <Form.Control size="sm" type="text" placeholder="" name="Estatura" value={this.state.alumno.Estatura} onChange={this.onChange} />
                                    </Form.Group>
                                </Form.Row>

                                <Form.Row>
                                    <Form.Group as={Col} md="2">
                                        <Form.Label>Fec.Nac.</Form.Label>
                                        <Form.Control size="sm" type="date" name="FechaNac" value={this.state.alumno.FechaNac} onChange={this.onChange} />
                                    </Form.Group>
                                    <Form.Group as={Col} md="1">
                                        <Form.Label>Edad</Form.Label>
                                        <Form.Control size="sm" type="text" value={this.state.edad} readOnly />
                                    </Form.Group>
                                    <Form.Group as={Col} md="2">
                                        <Form.Label>Fec.Ing.</Form.Label>
                                        <Form.Control size="sm" type="date" name="FechaIng" value={this.state.alumno.FechaIng} onChange={this.onChange} />
                                    </Form.Group>
                                    <Form.Group as={Col} md="2">
                                        <Form.Label>Ult.Ex.</Form.Label>
                                        <Form.Control size="sm" type="date" name="FechaUltEx" value={this.state.alumno.FechaUltEx} onChange={this.onChange} />
                                    </Form.Group>
                                    <Form.Group as={Col}>
                                        <Form.Label>Direccion</Form.Label>
                                        <Form.Control size="sm" type="text" name="Direccion" value={this.state.alumno.Direccion} onChange={this.onChange} />
                                    </Form.Group>
                                </Form.Row>

                                <Form.Row>
                                    <Form.Group as={Col} md="5">
                                        <Form.Label>Escuela</Form.Label>
                                        <Form.Control as="select" name="Escuela" value={this.state.alumno.Escuela} onChange={this.onChange}>
                                            {this.state.escuelas.map((i,k) => 
                                                <option key={i.I} value={i.I}>{i.N}</option>
                                            )}
                                        </Form.Control>
                                    </Form.Group>
                                    <Form.Group as={Col} md="2">
                                        <Form.Label>Telefono</Form.Label>
                                        <Form.Control size="sm" type="text" placeholder="Telefono" name="Telefono" value={this.state.alumno.Telefono} onChange={this.onChange} />
                                    </Form.Group>
                                    <Form.Group as={Col} md="2">
                                        <Form.Label>Celular</Form.Label>
                                        <Form.Control size="sm" type="text" placeholder="Celular" name="Celular" value={this.state.alumno.Celular} onChange={this.onChange} />
                                    </Form.Group>
                                    <Form.Group as={Col} md="2">
                                        <Form.Label>Sexo</Form.Label><br />
                                        <Form.Check inline label="Hombre" type="radio" name="Sexo" onChange={this.onChange} value="H" checked={this.state.alumno.Sexo === 'H'} />
                                        <Form.Check inline label="Mujer" type="radio" name="Sexo" onChange={this.onChange} value="M" checked={this.state.alumno.Sexo === 'M'} />
                                    </Form.Group>
                                </Form.Row>

                                <Form.Row>
                                    <Form.Group as={Col} md="5">
                                        <Form.Label>Estado Civil</Form.Label><br />
                                        <Form.Check inline label="Soltero" type="radio" name="EdoCivil" onChange={this.onChange} value="1" checked={this.state.alumno.EdoCivil == '1'}/>
                                        <Form.Check inline label="Casado" type="radio" name="EdoCivil" onChange={this.onChange} value="2" checked={this.state.alumno.EdoCivil == '2'}/>
                                        <Form.Check inline label="Divorciado" type="radio" name="EdoCivil" onChange={this.onChange}value="3" checked={this.state.alumno.EdoCivil == '3'}/>
                                        <Form.Check inline label="Viudo" type="radio" name="EdoCivil" onChange={this.onChange} value="4" checked={this.state.alumno.EdoCivil == '4'}/>
                                        <Form.Check inline label="Union libre" type="radio" name="EdoCivil" onChange={this.onChange} value="5" checked={this.state.alumno.EdoCivil == '5'}/>
                                    </Form.Group>
                                    <Form.Group as={Col} md="4">
                                        <Form.Label>Grado actual</Form.Label>
                                        <Form.Control as="select" name="GradoActual" value={this.state.alumno.GradoActual} onChange={this.onChange}>
                                        {this.state.grados.map((i,k) => 
                                                <option key={i.I} value={i.I}>{i.N}</option>
                                            )}
                                        </Form.Control>
                                    </Form.Group>
                                    <Form.Group as={Col} md="3">
                                        <Form.Label>Grado a subir</Form.Label>
                                        <Form.Control as="select" name="SubGradoActual" value={this.state.alumno.SubGradoActual} onChange={this.onChange}>
                                        {this.state.grados.map((i,k) => 
                                                <option key={i.I} value={i.I}>{i.N}</option>
                                            )}
                                        </Form.Control>
                                    </Form.Group>
                                </Form.Row>

                                <Form.Row>
                                    <Form.Group as={Col} md="2">
                                        <Form.Label>Trabajo</Form.Label>
                                        <Form.Control size="sm" type="text" placeholder="DEmpresa" name="" value={this.state.alumno.DEmpresa} onChange={this.onChange} />
                                    </Form.Group>
                                    <Form.Group as={Col} md="2">
                                        <Form.Label>Ocupacion</Form.Label>
                                        <Form.Control size="sm" type="text" name="DOcupacion" value={this.state.alumno.DOcupacion} onChange={this.onChange} />
                                    </Form.Group>
                                    <Form.Group as={Col} md="2">
                                        <Form.Label>Telefono</Form.Label>
                                        <Form.Control size="sm" type="text" name="DTelefono" value={this.state.alumno.DTelefono} onChange={this.onChange} />
                                    </Form.Group>
                                    <Form.Group as={Col} md="2">
                                        <Form.Label>Direccion</Form.Label>
                                        <Form.Control size="sm" type="text" name="DDireccion" value={this.state.alumno.DDireccion} onChange={this.onChange} />
                                    </Form.Group>
                                    <Form.Group as={Col} md="2">
                                        <Form.Label>Puesto</Form.Label>
                                        <Form.Control size="sm" type="text" name="DPuesto" value={this.state.alumno.DPuesto} onChange={this.onChange} />
                                    </Form.Group>
                                    <Form.Group as={Col} md="2">
                                        <Form.Label>Responsable</Form.Label>
                                        <Form.Control size="sm" type="text" name="DResponsable" value={this.state.alumno.DResponsable} onChange={this.onChange} />
                                    </Form.Group>
                                </Form.Row>
                            </div>
                        </Tab>

                        <Tab eventKey="profile" title="Datos de los padres">
                            <div>
                                <Form.Row>
                                    <Form.Group as={Col} md="3">
                                        <Form.Label>Padre</Form.Label>
                                        <Form.Control size="sm" type="text" placeholder="Nombre" name="Padre" value={this.state.alumno.Padre} onChange={this.onChange} />
                                    </Form.Group>
                                    <Form.Group as={Col} md="2">
                                        <Form.Label>Ocupacion</Form.Label>
                                        <Form.Control size="sm" type="text" name="OcupPadre" value={this.state.alumno.OcupPadre} onChange={this.onChange} />
                                    </Form.Group>
                                    <Form.Group as={Col} md="2">
                                        <Form.Label>Telefono</Form.Label>
                                        <Form.Control size="sm" type="text" name="TelPadre" value={this.state.alumno.TelPadre} onChange={this.onChange} />
                                    </Form.Group>
                                    <Form.Group as={Col} md="2">
                                        <Form.Label>Celular</Form.Label>
                                        <Form.Control size="sm" type="text" name="CelPadre" value={this.state.alumno.CelPadre} onChange={this.onChange} />
                                    </Form.Group>
                                    <Form.Group as={Col} md="2">
                                        <Form.Label>Empleo</Form.Label>
                                        <Form.Control size="sm" type="text" name="EmpPadre" value={this.state.alumno.EmpPadre} onChange={this.onChange} />
                                    </Form.Group>
                                    <Form.Group as={Col} md="1">
                                        <Form.Label>Puesto</Form.Label>
                                        <Form.Control size="sm" type="text" name="PtoPadre" value={this.state.alumno.PtoPadre} onChange={this.onChange} />
                                    </Form.Group>
                                </Form.Row>

                                <Form.Row>
                                    <Form.Group as={Col} md="3">
                                        <Form.Label>Madre</Form.Label>
                                        <Form.Control size="sm" type="text" placeholder="Nombre" name="Madre" value={this.state.alumno.Madre} onChange={this.onChange} />
                                    </Form.Group>
                                    <Form.Group as={Col} md="2">
                                        <Form.Label>Ocupacion</Form.Label>
                                        <Form.Control size="sm" type="text" name="OcupMadre" value={this.state.alumno.OcupMadre} onChange={this.onChange} />
                                    </Form.Group>
                                    <Form.Group as={Col} md="2">
                                        <Form.Label>Telefono</Form.Label>
                                        <Form.Control size="sm" type="text" name="TelMadre" value={this.state.alumno.TelMadre} onChange={this.onChange} />
                                    </Form.Group>
                                    <Form.Group as={Col} md="2">
                                        <Form.Label>Celular</Form.Label>
                                        <Form.Control size="sm" type="text" name="CelMadre" value={this.state.alumno.CelMadre} onChange={this.onChange} />
                                    </Form.Group>
                                    <Form.Group as={Col} md="2">
                                        <Form.Label>Empleo</Form.Label>
                                        <Form.Control size="sm" type="text" name="EmpMadre" value={this.state.alumno.EmpMadre} onChange={this.onChange} />
                                    </Form.Group>
                                    <Form.Group as={Col} md="1">
                                        <Form.Label>Puesto</Form.Label>
                                        <Form.Control size="sm" type="text" name="PtoMadre" value={this.state.alumno.PtoMadre} onChange={this.onChange} />
                                    </Form.Group>
                                </Form.Row>
                            </div>
                        </Tab>

                        <Tab eventKey="contact" title="Otros datos">
                            <div>
                                <Form.Row>
                                    <Form.Group as={Col} md="2">
                                        <Form.Label>Recomendado por</Form.Label>
                                        <InputGroup size="sm" className="mb-3">
                                            <Form.Control size="sm" type="text" name="Recomendado" value={this.state.alumno.Recomendado} readOnly/>
                                            <InputGroup.Append>
                                                <OverlayTrigger trigger="click" placement="right" overlay={popReco} rootClose={true}>
                                                    <Button variant="success">Buscar</Button>
                                                </OverlayTrigger>
                                            </InputGroup.Append>
                                        </InputGroup>
                                    </Form.Group>
                                    <Form.Group as={Col} md="4">
                                        <Form.Label><br/></Form.Label>
                                        <Form.Control size="sm" type="text" value={this.state.alumno.NombreRecomendado} readOnly/>
                                    </Form.Group>
                                    <Form.Group as={Col} md="5">
                                        <Form.Label>Promocion</Form.Label>
                                        <Form.Control size="sm" as="select" name="IdPromocion" value={this.state.alumno.IdPromocion} onChange={this.onChange}>
                                            {this.state.promociones.map((i,k) =>
                                                <option key={i.I} value={i.I}>{i.N}</option>
                                            )}
                                        </Form.Control>
                                    </Form.Group>
                                </Form.Row>
                                <Form.Row>
                                    <Form.Group as={Col} md="2">
                                        <Form.Label>Puntos Anterior</Form.Label>
                                        <Form.Control size="sm" type="text" name="Puntos" value={this.state.alumno.Puntos} onChange={this.onChange} />
                                    </Form.Group>
                                    <Form.Group as={Col} md="2">
                                        <Form.Label>Puntos Eventos</Form.Label>
                                        <InputGroup size="sm" className="mb-3">
                                            <Form.Control size="sm" type="text" value={this.state.alumno.PuntosEv} readOnly/>
                                            <InputGroup.Append>
                                                <OverlayTrigger trigger="click" placement="right" overlay={
                                                    <Popover id="popover-basic" title="Eventos">
                                                        <Buscador urlws={this.props.urlws} onSelect={null} tipo="eventos" mapearCampos={this.mapearEventos} clave={this.props.alumno.Clave} columnas={["Evento","Puntos","Inicio","Fin","Comentarios"]}/>
                                                    </Popover>
                                                } 
                                                rootClose={true}>
                                                    <Button variant="success">Detalle</Button>
                                                </OverlayTrigger>
                                            </InputGroup.Append>
                                        </InputGroup>
                                    </Form.Group>
                                    <Form.Group as={Col} md="2">
                                        <Form.Label>Puntaje Total</Form.Label>
                                        <Form.Control size="sm" type="text" value={this.state.alumno.PuntosTot} readOnly />
                                    </Form.Group>
                                </Form.Row>

                                <Form.Row>
                                    <Form.Group as={Col} md="1">
                                        <Form.Label>Faltas</Form.Label>
                                        <Form.Control size="sm" type="text" name="Faltas" value={this.state.alumno.Faltas} onChange={this.onChange} />
                                    </Form.Group>
                                    <Form.Group as={Col} md="1">
                                        <Form.Label>Asistencias</Form.Label>
                                        <Form.Control size="sm" type="text" name="Asistencias" value={this.state.alumno.Asistencias} onChange={this.onChange} />
                                    </Form.Group>
                                    <Form.Group as={Col} md="2">
                                        <Form.Label>Categoria</Form.Label>
                                        <Form.Control size="sm" type="text" name="Catego" value={this.state.alumno.Catego} onChange={this.onChange} />
                                    </Form.Group>
                                    <Form.Group as={Col} md="3">
                                        <Form.Label>Enterado por</Form.Label>
                                        <Form.Control size="sm" as="select" name="TipoPub" value={this.state.alumno.TipoPub} onChange={this.onChange}>
                                            {this.state.medios.map((i,k) =>
                                                <option key={i.I} value={i.I}>{i.N}</option>
                                            )}
                                        </Form.Control>
                                    </Form.Group>
                                    <Form.Group as={Col} md="2">
                                        <Form.Label>Otro</Form.Label>
                                        <Form.Control size="sm" type="text" name="EnteradoPorOtro" value={this.state.alumno.EnteradoPorOtro} onChange={this.onChange} />
                                    </Form.Group>
                                    <Form.Group as={Col} md="2">
                                        <br/>
                                        <Form.Check inline label="Cuota especial" type="checkbox" name="CuotaEspecial" checked={this.state.alumno.CuotaEspecial} onChange={this.onChecked} />
                                    </Form.Group>
                                </Form.Row>
                            </div>
                        </Tab>
                    </Tabs>
                </Form>
                <br />
                <Button variant="primary" type="submit" onClick={this.guardar}>Aceptar</Button>
                {'  '}
                <Button variant="danger" onClick={this.props.onClose}>Cancelar</Button>
            </Container>
        );
    }
}