import React from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Alumnos from './componentes/Alumnos';
import Escuelas from './componentes/Escuelas';
import Doyangs from './componentes/Doyangs';
import Profesores from './componentes/Profesores';
import Instructores from './componentes/Instructores';
import Articulos from './componentes/Articulos';
import Grupos from './componentes/Grupos';
import Eventos from './componentes/Eventos';
import Pagos from './componentes/Pagos';
import Examenes from './componentes/Examenes';
import ExamenesAsig from './componentes/ExamenesAsig';
import ExamenesAlumnos from './componentes/ExamenesAlumnos';
import Usuarios from './componentes/Usuarios';
import Login from './componentes/Login';
import SideBarMenu from './SideBarMenu';
class App extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      u:{id:0,nombre:'',admin:false,doyang:0},
      lg:false,
      opc:''
    }
  }
  opcionSeleccionada = (opc) => {
      this.setState({opc});
  }
  iniciar = (u) =>{
    const dys = u.Doyangs.split(",");
    const usu = {id:u.Id,nombre:u.Nombre,admin:u.Admin,doyang:u.Doyang, doyangs:dys};    
    this.setState({lg:true, u:usu});
  }
  render() {
    let urlws = 'https://www.orientews.isaerpweb.com';
    //urlws = 'http://localhost:1673';
    const estilo = {visibility : this.state.lg?'visible':'hidden'};
    return (
      <div>
      { this.state.lg ? null : <Login iniciar={this.iniciar} urlws={urlws}/>}
      <div style={estilo}>
        <Navbar bg="dark" expand="lg">
          <Navbar.Brand>
            <span style={{ color: '#fff' }}>Oriente</span>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <span style={{ color: '#fff' }}>usuario:{this.state.u.nombre}</span>
          </Navbar.Brand>
        </Navbar>
        <div class="wrapper">
          <SideBarMenu opcionSeleccionada = {this.opcionSeleccionada} usuario={this.state.u}/>
          <div id="content">
            {this.state.opc === "Usuarios" ?<Usuarios urlws={urlws} usuario={this.state.u} />: null}
            {this.state.opc === "Alumnos" ?<Alumnos urlws={urlws} usuario={this.state.u} />: null}
            {this.state.opc === "Escuelas" ?<Escuelas urlws={urlws} usuario={this.state.u}/>: null}
            {this.state.opc === "Doyangs" ?<Doyangs urlws={urlws} />: null}
            {this.state.opc === "Profesores" ?<Profesores urlws={urlws} usuario={this.state.u}/>: null}
            {this.state.opc === "Instructores" ?<Instructores urlws={urlws} usuario={this.state.u}/>: null}
            {this.state.opc === "Articulos" ?<Articulos urlws={urlws} />: null}
            {this.state.opc === "Grupos" ?<Grupos urlws={urlws} usuario={this.state.u}/>: null}
            {this.state.opc === "Eventos" ?<Eventos urlws={urlws} usuario={this.state.u}/>: null}
            {this.state.opc === "Pagos" ?<Pagos urlws={urlws} usuario={this.state.u}/>: null}
            {this.state.opc === "Examenes" ?<Examenes urlws={urlws} usuario={this.state.u}/>: null}
            {this.state.opc === "ExamenesAsig" ?<ExamenesAsig urlws={urlws} usuario={this.state.u}/>: null}
            {this.state.opc === "ExamenesAlumnos" ?<ExamenesAlumnos urlws={urlws} usuario={this.state.u}/>: null}
          </div>
        </div>
      </div>
      </div>
    )
  }
}

export default App;
