import React from 'react';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';

export default class Opciones extends React.Component {
    onOpcionClick = (e) => {
        this.props.opcionSeleccionada(e);
    }
    render() {
        return (
            <Accordion defaultActiveKey="0" style={{ width: 200 }}>
                <Card>
                    <Accordion.Toggle as={Card.Header} eventKey="0">
                        <span>Catalogos</span>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="0" >
                        <ListGroup defaultActiveKey="#link1">
                            <ListGroup.Item action onClick={() => this.onOpcionClick("Usuarios")}>
                                <span>Usuarios</span>
                            </ListGroup.Item>
                            <ListGroup.Item action onClick={() => this.onOpcionClick("Profesores")}>
                                <span>Profesores</span>
                            </ListGroup.Item>
                            <ListGroup.Item action onClick={() => this.onOpcionClick("Instructores")}>
                                <span>Instructores</span>
                            </ListGroup.Item>
                            <ListGroup.Item action onClick={() => {if(this.props.usuario.doyang === 0) this.onOpcionClick("Doyangs")}}>
                                <span>Doyangs</span>
                            </ListGroup.Item>
                            <ListGroup.Item action onClick={() => this.onOpcionClick("Alumnos")}>
                                <span>Alumnos</span>
                            </ListGroup.Item>
                            <ListGroup.Item action onClick={() => this.onOpcionClick("Escuelas")}>
                                <span>Escuelas</span>
                            </ListGroup.Item>
                            <ListGroup.Item action onClick={() => {if(this.props.usuario.doyang === 0) this.onOpcionClick("Articulos")}}>
                                <span>Articulos</span>
                            </ListGroup.Item>
                        </ListGroup>
                    </Accordion.Collapse>
                </Card>
                <Card>
                    <Accordion.Toggle as={Card.Header} eventKey="1">
                        <span>Operaciones</span>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="1">
                        <ListGroup defaultActiveKey="#link1">                            
                            <ListGroup.Item action onClick={() => this.onOpcionClick("Grupos")}>
                                <span>Grupos</span>
                            </ListGroup.Item>
                            <ListGroup.Item action onClick={() => this.onOpcionClick("Eventos")}>
                                <span>Eventos</span>
                            </ListGroup.Item>
                            <ListGroup.Item action onClick={() => this.onOpcionClick("Torneos")}>
                                <span>Torneos</span>
                            </ListGroup.Item>
                            <ListGroup.Item action onClick={() => this.onOpcionClick("Pagos")}>
                                <span>Pagos</span>
                            </ListGroup.Item>
                            <ListGroup.Item action onClick={() => this.onOpcionClick("Ventas")}>
                                <span>Ventas</span>
                            </ListGroup.Item>
                        </ListGroup>
                    </Accordion.Collapse>
                </Card>
                <Card>
                    <Accordion.Toggle as={Card.Header} eventKey="2">
                        <span>Examenes</span>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="2">
                        <ListGroup defaultActiveKey="#link2">
                            <ListGroup.Item action onClick={() => this.onOpcionClick("Examenes")}>
                                <span>Registro</span>
                            </ListGroup.Item>
                            <ListGroup.Item action onClick={() => this.onOpcionClick("ExamenesAsig")}>
                                <span>Asignacion</span>
                            </ListGroup.Item>
                            <ListGroup.Item action onClick={() => this.onOpcionClick("ExamenesAlumnos")}>
                                <span>Alumnos</span>
                            </ListGroup.Item>
                        </ListGroup>
                    </Accordion.Collapse>
                </Card>
            </Accordion>
        )
    }
}