import React from 'react';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Form from 'react-bootstrap/Form';
import Table from 'react-bootstrap/Table';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Spinner from 'react-bootstrap/Spinner';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';

import Buscador from './Buscador';

export default class Grupos extends React.Component {
    datoInicial = {Id:0,Doyang:0,Profesor:0,Periodo:0,Anio:0,Horario:0,Nombre:'',Lun:false,Mar:false,Mie:false,Jue:false,Vie:false,Sab:false,Dom:false, Instructor:0,Certificador:0}

    constructor(props) {
        super(props);
        this.state = {
            cargando:true,
            formVisible: false,
            doyang:0,
            id: 0,
            nombre:'',
            dato:{},
            datos: [],
            doyangs:[],
            profesores:[],
            horarios:[],
            alumnos:[],
            periodos:[],
            instructores:[],
            certificadores:[]
        };
        this.cargarCatalogos();
        this.cargarLista();
    }
    onFormClose = () => {
        this.setState({
            formVisible: false
        });
    }
    cargarCatalogos = async() =>{
        let res = await fetch(this.props.urlws + "/home/periodos");
        const periodos = await res.json();
        res = await fetch(this.props.urlws + "/home/horarios");
        const horarios = await res.json();
        res = await fetch(`${this.props.urlws}/doyang/listaUsuario/${this.props.usuario.id}`);
        const dys = await res.json();
        const du = this.props.usuario.doyang;
        const doyangs = dys; //filter((d)=> du === 0 || du === d.Id);
        const doyang = (du > 0) ? du : 0;
        this.setState({ periodos, horarios, doyangs, doyang });
    }
    cargarProfesores = async() =>{
        let res = await fetch(this.props.urlws + "/profesor/lista/" + this.state.doyang);
        const profesores = await res.json();
        res = await fetch(this.props.urlws + "/profesor/certificados/" + this.state.doyang);
        const certificadores = await res.json();
        res = await fetch(this.props.urlws + "/instructor/lista/" + this.state.doyang);
        const instructores = await res.json();
        this.setState({profesores, instructores, certificadores });
    }

    cargarLista = async () => {
        this.setState({cargando:true});
        const url = this.props.urlws + "/grupo/lista/" + this.state.doyang;
        const resp = await fetch(url);
        const datos = await resp.json();
        let res = await fetch(this.props.urlws + "/profesor/lista/" + this.state.doyang);
        const profesores = await res.json();
        res = await fetch(this.props.urlws + "/profesor/certificados/" + this.state.doyang);
        const certificadores = await res.json();
        res = await fetch(this.props.urlws + "/instructor/lista/" + this.state.doyang);
        const instructores = await res.json();
        this.setState({profesores, instructores, certificadores, cargando:false, datos });
    }
    onSubmit = (e) => {
        e.preventDefault();
        this.cargarLista();
    }
    onChange = (e) => {
        this.setState({ [e.target.name] : e.target.value});
    }
    onBntBuscarClick = () =>{
        this.cargarLista();
    }    
    agregarAlumno = async(mat) =>{
        for(let x = 0; x < this.state.alumnos.length; x++){
            console.log(this.state.alumnos[x]);
            if(this.state.alumnos[x].Clave === mat){
                Swal.fire('Atencion','Este alumno ya esta en este grupo','error');
                return;
            }
        }
        let url =`${this.props.urlws}/grupo/agregarAlumno/${this.state.id}?alu=${mat}`;
        let resp = await fetch(url);
        let res = await resp.text();
        if(res === "ok"){
            Swal.fire('Atencion','Se agrego el alumno','success');
            url = this.props.urlws + "/grupo/alumnos/" + this.state.id;
            resp = await fetch(url);
            const alumnos = await resp.json();
            this.setState({alumnos});
        }else
            Swal.fire('Atencion',res,'error');
    }
    removerAlumno = async(mat) =>{
        let url =`${this.props.urlws}/grupo/removerAlumno/${this.state.id}?alu=${mat}`;
        let resp = await fetch(url);
        let res = await resp.text();
        if(res === "ok"){
            Swal.fire('Atencion','Se removio el alumno','success');
            url = `${this.props.urlws}/grupo/alumnos/` + this.state.id;
            resp = await fetch(url);
            const alumnos = await resp.json();
            this.setState({alumnos});
        }else
            Swal.fire('Atencion',res,'error');
    }
    editarAlumno = async(mat,cat) =>{
        let url =`${this.props.urlws}/grupo/editarAlumno/${this.state.id}?alu=${mat}&cat=${cat}`;
        let resp = await fetch(url);
        let res = await resp.text();
        if(res === "ok"){
            url = this.props.urlws + "/grupo/alumnos/" + this.state.id;
            resp = await fetch(url);
            const alumnos = await resp.json();
            this.setState({alumnos});
        }else
            Swal.fire('Atencion',res,'error');
    }
    imprimirLista =  (cve) => {
        const url = `${this.props.urlws}/grupo/imprimirAsistencia/${cve}`;
        window.open(url, '', '');
    }
    imprimirPagos =  (cve) => {
        const url = `${this.props.urlws}/grupo/imprimirPagos/${cve}`;
        window.open(url, '', '');
    }
    cargar = async (cve) => {
        this.setState({cargando:true});
        let url = this.props.urlws + "/grupo/cargar/" + cve;
        let resp = await fetch(url);
        const dato = await resp.json();
        
        url = this.props.urlws + "/grupo/alumnos/" + cve;
        resp = await fetch(url);
        const alumnos = await resp.json();

        this.setState({
            cargando:false,
            formVisible: true,
            id: cve,
            dato,
            alumnos
        });
    }
    guardar = async(d) =>{
        const url = this.props.urlws + "/grupo/guardar";
        if(d.Doyang === 0)
           d.Doyang = this.state.doyang;
        this.setState({cargando:true,dato:d});
        const json = JSON.stringify(d);
        const resp = await fetch(url,{
            method: 'POST', // or 'PUT'
            body: json // data can be `string` or {object}!
          });
        const res = await resp.text();
        this.setState({cargando:false});
        if(res === "ok"){
            Swal.fire('Atencion','Datos guardados','success');
            this.onFormClose();
            this.cargarLista();
        }
        else
            Swal.fire('Atencion',res,'error');
    }
    nuevo = () => {
        if(this.state.doyang === 0){
            Swal.fire('Atencion','Especifique el Doyang','error');
            return;
        }
        let esc = {...this.datoInicial, Doyang:this.state.doyang};
        this.setState({
            formVisible: true,
            id:0,
            dato:esc
        });
    }
    render() {
        const estilo = { display: this.state.formVisible ? 'none' : 'inline' };
        const urlws = this.props.urlws + "/alumno/lista?d=" + this.state.doyang;
        return (
            <Container >
                {this.state.cargando ?
                <div className="loader">
                    <Spinner animation="border" variant="primary"/>
                </div>
                : 
                null
                }
                <div style={estilo}>
                <Navbar bg="light" expand="lg">
                    <Navbar.Brand size="sm" href="#home">Grupos</Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="mr-auto" size="sm">
                            <Button size="sm" variant="success" onClick={this.nuevo}>+</Button>
                        </Nav>
                        <Form inline onSubmit={this.onSubmit} >
                            <Form.Label size="sm">Doyang</Form.Label>&nbsp;
                            <Form.Control size="sm" as="select" name="doyang" value={this.state.doyang} onChange={this.onChange}>
                                <option key={-1} value={-1}>Especifique</option>    
                                {this.state.doyangs.map((i,k) =>(
                                    <option key={i.Id} value={i.Id}>{i.Nombre}</option>    
                                ))}
                            </Form.Control>
                            &nbsp;
                            <Button size="sm" variant="success" onClick={this.onBntBuscarClick}>Buscar</Button>
                        </Form>
                    </Navbar.Collapse>
                </Navbar>
                <div style={{ height: 4 }}></div>
                    <GrupoTable datos={this.state.datos} height={500} width={990} onRowClick={this.cargar} onListaClick={this.imprimirLista}  onPagosClick={this.imprimirPagos}/>
                </div>
                <GrupoForm alumnos={this.state.alumnos} horarios={this.state.horarios} profesores={this.state.profesores} instructores={this.state.instructores} certificadores={this.state.certificadores} periodos={this.state.periodos} visible={this.state.formVisible} dato={this.state.dato} onClose={this.onFormClose} clave={this.state.Id} guardar={this.guardar} agregar={this.agregarAlumno} remover={this.removerAlumno} editarAlumno={this.editarAlumno} urlws={urlws}/>
            </Container>
        );
    }
}

class GrupoTable extends React.Component {
    onRowClick = (cve) => {
        this.props.onRowClick(cve);
    }
    onListaClick = (cve) =>{
        this.props.onListaClick(cve);
    }
    onPagosClick = (cve) =>{
        this.props.onPagosClick(cve);
    }
    render() {
        let { datos } = this.props;
        return (
            <div style={{ height: this.props.height, width:this.props.width, overflow: 'auto' }}>
            <Table striped bordered hover size="sm">
                <thead>
                    <tr>
                        <th style={{width:100}}>Clave</th>
                        <th>Nombre</th>
                        <th>Profesor</th>
                        <th>Año</th>
                        <th>Periodo</th>
                        <th></th>
                        <th></th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {datos.map((esc, i) => (
                        <GrupoRow key={i} dato={esc} onRowClick={this.onRowClick} onListaClick={this.onListaClick} onPagosClick={this.onPagosClick}/>
                    ))}
                </tbody>
                </Table>
            </div>
        );
    }
}

class GrupoRow extends React.Component{
    onClick = (e) =>{
        this.props.onRowClick(this.props.dato.Id);
    }
    onListaClick = (e) =>{
        this.props.onListaClick(this.props.dato.Id);
    }
    onPagosClick = (e) =>{
        this.props.onPagosClick(this.props.dato.Id);
    }
    render(){
        let {dato} = this.props;
        return (
        <tr>
            <td>{dato.Id}</td>
            <td>{dato.Nombre}</td>
            <td>{dato.Profesor}</td>
            <td>{dato.Anio}</td>
            <td>{dato.Periodo}</td>
            <td>
                <Button variant="primary" size="sm" onClick={this.onClick}>
                    <img src="/imagenes/pencil.png" alt="editar" title="editar"/>
                </Button>
            </td>
            <td>
                <Button variant="primary" size="sm" onClick={this.onListaClick}>
                    <img src="/imagenes/lista.png" alt="lista" title="lista"/>
                </Button>
            </td>
            <td>
                <Button variant="primary" size="sm" onClick={this.onPagosClick}>
                    <img src="/imagenes/pago.png" alt="pagos" title="pagos"/>
                </Button>
            </td>
        </tr>
        );
    }
}

class GrupoForm extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            //...this.props.alumno,
            dato:this.props.dato,
        }
    }
    componentWillReceiveProps(newProps){
        this.setState({
            dato:newProps.dato
        });
    }
    onChange = (e) => {
        const esc = {
            ...this.state.dato,
            [e.target.name] : e.target.value
        };
        this.setState({dato:esc});
    }
    onChecked = (e) => {
        const esc = {
            ...this.state.dato,
            [e.target.name] : e.target.checked
        };
        this.setState({dato:esc});
    }
    remover = (cve) =>{
        this.props.remover(cve);
    }
    guardar = () =>{
        const MySwal = withReactContent(Swal)
        MySwal.fire({
            title: 'Confirme',
            text: "¿ Desea guardar estos datos ?",
            type: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'No',
            confirmButtonText: 'Si'
          }).then((result) => {
            if (result.value) {
                this.props.guardar(this.state.dato);
            }
          })        
    }
    mapearAlumnos = (item) => {
        return (
            <React.Fragment>
                <td>{item.Clave}</td>
                <td>{item.Nombre}</td>
            </React.Fragment>
        )
    }
    matAgregar = (mat,nom) => {
        this.props.agregar(mat);
    }
    render() {
        const estilo = { display: this.props.visible ? 'inline' : 'none' };
        if(this.props.dato === undefined)
            return null;
        if(Object.keys(this.props.dato).length === 0)
            return null;
        const alus = this.state.dato.Id > 0;
        let popAlu = (
            <Popover id="popover-basic" title="Buscar alumno">
                <Buscador urlws={this.props.urlws} onSelect={this.matAgregar} tipo="alumno" mapearCampos={this.mapearAlumnos} columnas={["Clave","Nombre"]}/>
            </Popover>
        );    
        return (
            <Container style={estilo}>
                <Form>
                    <div>
                        <Form.Row>
                            <Form.Group as={Col} md="2">
                                <Form.Label>Clave</Form.Label>
                                <Form.Control size="sm" type="text" name="Id" value={this.state.dato.Id} />
                            </Form.Group>
                            <Form.Group as={Col} md="10">
                                <Form.Label>Nombre</Form.Label>
                                <Form.Control size="sm" type="text" name="Nombre" value={this.state.dato.Nombre} onChange={this.onChange} />
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col} md="4">
                                <Form.Label>Profesor</Form.Label>
                                <Form.Control size="sm" as="select" name="Profesor" value={this.state.dato.Profesor} onChange={this.onChange}>
                                    <option value={0}>Especifique</option>
                                    {this.props.profesores.map((i, k) =>
                                        <option key={i.Id} value={i.Id}>{i.Nombre}</option>
                                    )}
                                </Form.Control>
                            </Form.Group>
                            <Form.Group as={Col} md="4">
                                <Form.Label>Instructor</Form.Label>
                                <Form.Control size="sm" as="select" name="Instructor" value={this.state.dato.Instructor} onChange={this.onChange}>
                                    <option value={0}>Especifique</option>
                                    {this.props.instructores.map((i, k) =>
                                        <option key={i.Id} value={i.Id}>{i.Nombre}</option>
                                    )}
                                </Form.Control>
                            </Form.Group>
                            <Form.Group as={Col} md="4">
                                <Form.Label>Profesor certificado</Form.Label>
                                <Form.Control size="sm" as="select" name="Certificador" value={this.state.dato.Certificador} onChange={this.onChange}>
                                    <option value={0}>Especifique</option>
                                    {this.props.certificadores.map((i, k) =>
                                        <option key={i.Id} value={i.Id}>{i.Nombre}</option>
                                    )}
                                </Form.Control>
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col} md="4">
                                <Form.Label>Periodo</Form.Label>
                                <Form.Control size="sm" as="select" name="Periodo" value={this.state.dato.Periodo} onChange={this.onChange}>
                                    <option value={0}>Especifique</option>
                                    {this.props.periodos.map((i, k) =>
                                        <option key={i.I} value={i.I}>{i.N}</option>
                                    )}
                                </Form.Control>
                            </Form.Group>
                            <Form.Group as={Col} md="4">
                                <Form.Label>Horario</Form.Label>
                                <Form.Control size="sm" as="select" name="Horario" value={this.state.dato.Horario} onChange={this.onChange}>
                                    <option value={0}>Especifique</option>
                                    {this.props.horarios.map((i, k) =>
                                        <option key={i.I} value={i.I}>{i.N}</option>
                                    )}
                                </Form.Control>
                            </Form.Group>
                        </Form.Row>

                        <Form.Row>
                            <Form.Group as={Col}>
                                <Form.Check inline label="Lunes" type="checkbox" name="Lun" checked={this.state.dato.Lun} onChange={this.onChecked} />
                                <Form.Check inline label="Martes" type="checkbox" name="Mar" checked={this.state.dato.Mar} onChange={this.onChecked}/>
                                <Form.Check inline label="Miercoles" type="checkbox" name="Mie" checked={this.state.dato.Mie} onChange={this.onChecked}/>
                                <Form.Check inline label="Jueves" type="checkbox" name="Jue" checked={this.state.dato.Jue} onChange={this.onChecked}/>
                                <Form.Check inline label="Viernes" type="checkbox" name="Vie" checked={this.state.dato.Vie} onChange={this.onChecked}/>
                                <Form.Check inline label="Sabado" type="checkbox" name="Sab" checked={this.state.dato.Sab} onChange={this.onChecked}/>
                                <Form.Check inline label="Domingo" type="checkbox" name="Dom" checked={this.state.dato.Dom} onChange={this.onChecked}/>
                            </Form.Group>
                        </Form.Row>
                    </div>
                </Form>
                <br />
                <Button variant="primary" type="submit" onClick={this.guardar}>Aceptar</Button>
                {'  '}
                <Button variant="danger" onClick={this.props.onClose}>Cancelar</Button>
                {alus ?
                    <React.Fragment>
                        {'  '}
                        <OverlayTrigger trigger="click" placement="right" overlay={popAlu} rootClose={true}>
                            <Button variant="success">
                                <img src="/imagenes/plus.png" alt="agregar" title="agregar"/>
                                &nbsp;Alumno
                            </Button>
                        </OverlayTrigger>
                        <br/>
                        <h3>Alumnos</h3>
                        <br/>
                        <AlumnosGrupoTable alumnos={this.props.alumnos} remover={this.remover} editar={this.props.editarAlumno}/>
                    </React.Fragment>
                    :
                    null
                }
            </Container>
        );
    }
}

class AlumnosGrupoTable extends React.Component {
    remover = (mat) => {
        this.props.remover(mat);
    }
    render() {
        let { alumnos } = this.props;
        return (
            <div style={{ height: this.props.height, width:this.props.width, overflow: 'auto' }}>
            <Table striped bordered hover size="sm">
                <thead>
                    <tr>
                        <th>Clave</th>
                        <th>Nombre</th>
                        <th>Grado</th>
                        <th>SubGrado</th>
                        <th>Puntos</th>
                        <th>Categoria</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {alumnos.map((alu, i) => (
                        <AlumnoGrupoRow key={i} alumno={alu} remover={this.remover} editar={this.props.editar}/>
                    ))}
                </tbody>
                </Table>
            </div>
        );
    }
}

class AlumnoGrupoRow extends React.Component{
    onClick = (e) =>{
        Swal.fire({
            title: this.props.alumno.Nombre,
            text: "Confirme, ¿ Remover del grupo a este alumno ?",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'No',
            confirmButtonText: 'Si'
          }).then((result) => {
            if (result.value) {
                this.props.remover(this.props.alumno.Clave);
            }
          });
    }
    onEdit = async() =>{
        const {value: catCap} = await Swal.fire({
            title: 'Siguiente categoria',
            input: 'text',
            inputValue: this.props.alumno.Categoria,
            inputPlaceholder: 'siguiente categoria'
          })
          
          if (catCap !== undefined) {
              if(catCap !== this.props.alumno.Categoria)
                 this.props.editar(this.props.alumno.Clave,catCap);
          }
    }
    render(){
        let {alumno} = this.props;
        return (
        <tr>
            <td>{alumno.Clave}</td>
            <td>{alumno.Nombre}</td>
            <td>{alumno.Grado}</td>
            <td>{alumno.SubGrado}</td>
            <td>{alumno.Puntos}</td>
            <td>
                <Button variant="primary" size="sm" onClick={this.onEdit}>
                    <img src="/imagenes/pencil.png" alt="editar"/>
                </Button>
                {alumno.Categoria}
            </td>
            <td><Button variant="primary" size="sm" onClick={this.onClick}>-</Button></td>
        </tr>
        );
    }
}