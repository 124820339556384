import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Table from 'react-bootstrap/Table';
import Col from 'react-bootstrap/Col';
import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Spinner from 'react-bootstrap/Spinner';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';

import Buscador from './Buscador';
export default class Pagos extends React.Component {
    constructor(props){
        super(props);
        let fec = new Date();
        fec.addDays(-7);
        let fecIni = fec.yyyymmdd('-');
        let fecFin = new Date().yyyymmdd('-');

        this.state = {
            cargando:true,
            editando:false,
            concepto:'',
            fechaIni:fecIni,
            fechaFin:fecFin,
            fecha:fecFin,
            importe:0,
            anio:0,
            mes:0,
            forma:0,
            claveAlumno:'',
            nombreAlumno:'',
            tipo:'',
            doyang:0,
            tipoEx:0,
            dato:{},
            doyangs:[],
            formas:[],
            pagos:[],
            tipos:[],
            meses:[],
            anios:[],
            tiposEx:[]
        };
        this.cargarCatalogos();
    }
    cargarCatalogos = async() =>{
        let res = await fetch(this.props.urlws + "/home/anios");
        const anios = await res.json();
        res = await fetch(this.props.urlws + "/home/meses");
        const meses = await res.json();
        res = await fetch(this.props.urlws + "/pago/tipos");
        const tipos = await res.json();
        res = await fetch(this.props.urlws + "/pago/formas");
        const formas = await res.json();
        res = await fetch(this.props.urlws + "/pago/tiposExamen");
        const tiposEx = await res.json();
        res = await fetch(`${this.props.urlws}/doyang/listaUsuario/${this.props.usuario.id}`);
        const dys = await res.json();
        const du = this.props.usuario.doyang;
        const doyangs = dys; //.filter((d)=> du === 0 || du === d.Id);
        const doyang = (du > 0) ? du : 0;
        this.setState({ meses,anios,formas, tipos, doyangs, doyang, tiposEx, cargando:false });
    }
    onChange = (e) =>{
        const campo = e.target.name;
        this.setState({
            [campo]: e.target.value
        });
    }
    mapearAlumnos = (item) => {
        return (
            <React.Fragment>
                <td>{item.Clave}</td>
                <td>{item.Nombre}</td>
            </React.Fragment>
        )
    }
    matRecomendado = (mat,nom) => {
        this.setState({claveAlumno:mat, nombreAlumno:nom});
    }
    cargarPagos = async() => {
        if(this.state.doyang === 0){
            Swal.fire('Atencion','Especifique el Doyang','error');
            return;
        }
        if(this.state.tipo === ''){
            Swal.fire('Atencion','Especifique el tipo de pago','error');
            return;
        }
        this.setState({cargando:true});
        const url = `${this.props.urlws}/pago/lista?d=${this.state.doyang}&cve=${this.state.tipo}&fecIni=${this.state.fechaIni}&fecFin=${this.state.fechaFin}`;
        const resp = await fetch(url);
        const pagos  = await resp.json();
        this.setState({cargando:false, pagos });
    }
    nuevo = () => {
        if(this.state.doyang === 0){
            Swal.fire('Atencion','Especifique el Doyang','error');
            return;
        }
        if(this.state.tipo === ''){
            Swal.fire('Atencion','Especifique el tipo de pago','error');
            return;
        }
        this.setState({
            fecha:new Date().yyyymmdd('-'),
            importe:0,
            concepto:'',
            forma:0,
            claveAlumno:'',
            nombreAlumno:'',
            editando:true
        });
    }
    cancelar = () =>{
        const MySwal = withReactContent(Swal)
        MySwal.fire({
            title: 'Confirme',
            text: "¿ Desea salir sin guardar este pago ?",
            type: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'No',
            confirmButtonText: 'Si'
          }).then((result) => {
            if (result.value) {
                this.setState({editando:false});
            }
          });
    }
    guardar = () =>{
        if(this.state.forma === 0 || this.state.claveAlumno === ''){
            Swal.fire('Atencion','Datos del pago incompletos','error');
            return;
        }
        const MySwal = withReactContent(Swal)
        MySwal.fire({
            title: 'Confirme',
            text: "¿ Desea guardar este pago ?",
            type: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'No',
            confirmButtonText: 'Si'
          }).then((result) => {
            if (result.value) {
                this.guardarB();
            }
          });
    }
    guardarB = async(escuela) =>{
        const url = this.props.urlws + "/pago/guardar";
        this.setState({cargando:true});
        const pago = {
            Doyang : this.state.doyang,
            Clave : this.state.tipo,
            Numero : 0,
            Fecha : this.state.fecha,
            ClaveAlumno : this.state.claveAlumno,
            Importe : this.state.importe,
            Concepto : this.state.concepto,
            FormaPago : this.state.forma,
            tipoExamen : this.state.tipoEx,
            MesIni:this.state.mes,
            MesFin:this.state.anio
        }
        const json = JSON.stringify(pago);
        const resp = await fetch(url,{
            method: 'POST',
            body: json
          });
        const res = await resp.text();
        this.setState({cargando:false});
        if(res.substring(0,5) !== "Error"){
            Swal.fire('Atencion','Datos guardados','success');
            this.setState({editando:false});
        }
        else
            Swal.fire('Atencion',res,'error');
    }

    render(){
        const urlws = this.props.urlws + "/alumno/lista?d=" + this.state.doyang;
        const estilo1 = { display: this.state.editando ? 'inline' : 'none' };
        const estilo2 = { padding:2, display: this.state.editando ? 'none' : 'inline' };
        console.log(this.state.dato);

        const popReco = (
            <Popover id="popover-basic" title="Buscar alumno">
                <Buscador urlws={urlws} onSelect={this.matRecomendado} tipo="alumno" mapearCampos={this.mapearAlumnos} columnas={["Clave","Nombre"]}/>
            </Popover>
        );    

        return (
            <Container>
                {this.state.cargando ?
                <div className="loader">
                    <Spinner animation="border" variant="primary"/>
                </div>
                : 
                null
                }
                <div className="row">
                    <Form.Group as={Col} md="4">
                        <Form.Label>Doyang</Form.Label>
                        <Form.Control size="sm" as="select" name="doyang" value={this.state.doyang} onChange={this.onChange}>
                            <option key="0" value="">Especifique</option>
                            {this.state.doyangs.map((i, k) =>
                                <option key={i.Id} value={i.Id}>{i.Nombre}</option>
                            )}
                        </Form.Control>
                    </Form.Group>
                    <Form.Group as={Col} md="2">
                        <Form.Label>Del dia</Form.Label>
                        <Form.Control size="sm" type="date" name="fechaIni" value={this.state.fechaIni} onChange={this.onChange} />
                    </Form.Group>
                    <Form.Group as={Col} md="2">
                        <Form.Label>al dia</Form.Label>
                        <Form.Control size="sm" type="date" name="fechaFin" value={this.state.fechaFin} onChange={this.onChange} />
                    </Form.Group>
                    <Form.Group as={Col} md="2">
                        <Form.Label>Tipo</Form.Label>
                        <Form.Control size="sm" as="select" name="tipo" value={this.state.tipo} onChange={this.onChange}>
                            <option key="0" value="">Especifique</option>
                            {this.state.tipos.map((i, k) =>
                                <option key={i.I} value={i.I}>{i.N}</option>
                            )}
                        </Form.Control>
                    </Form.Group>
                    <Form.Group as={Col}>
                        <Form.Label></Form.Label><br />
                        <Button variant='primary' onClick={this.cargarPagos}>Cargar</Button>
                        {'  '}
                        <Button variant='success' onClick={this.nuevo}>+</Button>
                    </Form.Group>
                </div>
                <div style={estilo2}>
                    <Table striped bordered hover size="sm">
                        <thead>
                            <tr>
                                <th style={{ width: 100 }}>Numero</th>
                                <th>Fecha</th>
                                <th>Clave</th>
                                <th>Nombre</th>
                                <th>Concepto</th>
                                <th>Importe</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.pagos.map((esc, i) => (
                                <tr>
                                    <td>{esc.Num}</td>
                                    <td>{esc.Fec}</td>
                                    <td>{esc.Cve}</td>
                                    <td>{esc.Nom}</td>
                                    <td>{esc.Con}</td>
                                    <td style={{textAlign:'right'}}>{esc.Imp}</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </div>
                <div style={estilo1}>
                <Modal.Dialog>
                    <Modal.Header closeButton>
                        <Modal.Title>Nuevo pago - {this.state.tipo}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div>
                            <Form.Row>
                                <Form.Group as={Col} md="4">
                                    <Form.Label>Alumno</Form.Label>
                                    <InputGroup size="sm" className="mb-3">
                                        <Form.Control size="sm" type="text" name="claveAlumno" value={this.state.claveAlumno} readOnly />
                                        <InputGroup.Append>
                                            <OverlayTrigger trigger="click" placement="right" overlay={popReco} rootClose={true}>
                                                <Button variant="success">Buscar</Button>
                                            </OverlayTrigger>
                                        </InputGroup.Append>
                                    </InputGroup>
                                </Form.Group>
                                <Form.Group as={Col} md="8">
                                    <Form.Label><br /></Form.Label>
                                    <Form.Control size="sm" type="text" value={this.state.nombreAlumno} readOnly />
                                </Form.Group>
                            </Form.Row>
                            <Form.Row>
                                <Form.Group as={Col} md="4">
                                    <Form.Label>Fecha</Form.Label>
                                    <Form.Control size="sm" type="date" name="fecha" value={this.state.fecha} onChange={this.onChange} />
                                </Form.Group>
                                <Form.Group as={Col} md="2">
                                    <Form.Label>Importe</Form.Label>
                                    <Form.Control size="sm" type="text" name="importe" value={this.state.importe} onChange={this.onChange} />
                                </Form.Group>
                                <Form.Group as={Col}>
                                    <Form.Label>Forma de pago</Form.Label>
                                    <Form.Control size="sm" as="select" name="forma" value={this.state.forma} onChange={this.onChange}>
                                        <option key="0" value="">Especifique</option>
                                        {this.state.formas.map((i, k) =>
                                            <option key={i.I} value={i.I}>{i.N}</option>
                                        )}
                                    </Form.Control>
                                </Form.Group>
                            </Form.Row>
                            <Form.Row>
                                <Form.Group as={Col}>
                                    <Form.Label>Concepto</Form.Label>
                                    <Form.Control size="sm" type="text" name="concepto" value={this.state.concepto} onChange={this.onChange} />
                                </Form.Group>
                            </Form.Row>
                                <Form.Row>
                                    {this.state.tipo == "EXA" ?
                                    <Form.Group as={Col} md="5">
                                        <Form.Label>Tipo de examen</Form.Label>
                                        <Form.Control size="sm" as="select" name="tipoEx" value={this.state.tipoEx} onChange={this.onChange}>
                                            <option key="0" value="">Especifique</option>
                                            {this.state.tiposEx.map((i, k) =>
                                                <option key={i.I} value={i.I}>{i.N}</option>
                                            )}
                                        </Form.Control>
                                    </Form.Group>
                                    :
                                    <React.Fragment>
                                    <Form.Group as={Col} md="5">
                                        <Form.Label>Año</Form.Label>
                                        <Form.Control size="sm" as="select" name="anio" value={this.state.anio} onChange={this.onChange}>
                                            <option key="0" value={0}>Especifique</option>
                                            {this.state.anios.map((i, k) =>
                                                <option key={i.I} value={i.I}>{i.N}</option>
                                            )}
                                        </Form.Control>
                                    </Form.Group>
                                    <Form.Group as={Col} md="5">
                                        <Form.Label>Mes</Form.Label>
                                        <Form.Control size="sm" as="select" name="mes" value={this.state.mes} onChange={this.onChange}>
                                            <option key="0" value={0}>Especifique</option>
                                            {this.state.meses.map((i, k) =>
                                                <option key={i.I} value={i.I}>{i.N}</option>
                                            )}
                                        </Form.Control>
                                    </Form.Group>
                                    </React.Fragment>
                                    }
                                </Form.Row>                            
                            <Form.Row>
                                <Form.Group as={Col} >
                                    <Button variant='success' onClick={this.guardar}>Guardar</Button>
                                    &nbsp;&nbsp;&nbsp;
                                    <Button variant='warning' onClick={this.cancelar}>Cancelar</Button>
                                </Form.Group>
                            </Form.Row>
                        </div>
                    </Modal.Body>
                </Modal.Dialog>
                </div>
            </Container>
        );       
    }
}