import React from 'react';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Form from 'react-bootstrap/Form';
import Table from 'react-bootstrap/Table';
import Col from 'react-bootstrap/Col';
import FormControl from 'react-bootstrap/FormControl';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Spinner from 'react-bootstrap/Spinner';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

export default class Escuelas extends React.Component {
    escuelaInicial = {Clave:'',Nombre:'',Director:'',Direccion:'',Telefono:'',Celular:'',Turno:'',Id:0,IdTipo:0};

    constructor(props) {
        super(props);
        this.state = {
            cargando:true,
            formVisible: false,
            clave: '',
            nombre:'',
            tipo:0,
            escuela:{},
            tipos:[],
            escuelas: []
        };
        this.cargarCatalogos();
        this.cargarEscuelas();
    }
    onFormClose = () => {
        this.setState({
            formVisible: false
        });
    }
    cargarCatalogos = async() =>{
        let res = await fetch(this.props.urlws + "/home/tiposEscuelas");
        const tipos = await res.json();
        this.setState({ tipos });
    }

    cargarEscuelas = async () => {
        this.setState({cargando:true});
        const url = `${this.props.urlws}/escuela/lista?n=${this.state.nombre}&t=${this.state.tipo}`;
        const resp = await fetch(url);
        const escuelas = await resp.json();
        this.setState({cargando:false, escuelas });
    }
    onSubmit = (e) => {
        e.preventDefault();
        this.cargarEscuelas();
    }
    onChange = (e) => {
        this.setState({ [e.target.name] : e.target.value});
    }
    onBntBuscarClick = () =>{
        this.cargarEscuelas();
    }    
    cargarEscuela = async (cve) => {
        this.setState({cargando:true});
        const url = this.props.urlws + "/escuela/cargar/" + cve;
        const resp = await fetch(url);
        const escuela = await resp.json();
        this.setState({
            cargando:false,
            formVisible: true,
            clave: cve,
            escuela
        });
    }
    guardarEscuela = async(escuela) =>{
        const url = this.props.urlws + "/escuela/guardar";
        this.setState({cargando:true});
        const json = JSON.stringify(escuela);
        const resp = await fetch(url,{
            method: 'POST', // or 'PUT'
            body: json // data can be `string` or {object}!
          });
        const res = await resp.text();
        this.setState({cargando:false});
        if(res === "ok"){
            Swal.fire('Atencion','Datos guardados','success');
            this.onFormClose();
        }
        else
            Swal.fire('Atencion',res,'error');
    }
    nuevaEscuela = () => {
        let esc = {...this.escuelaInicial};
        this.setState({
            formVisible: true,
            clave: 'NUEVO',
            escuela:esc
        });
    }
    render() {
        const estilo = { display: this.state.formVisible ? 'none' : 'inline' };
        return (
            <Container >
                {this.state.cargando ?
                <div className="loader">
                    <Spinner animation="border" variant="primary"/>
                </div>
                : 
                null
                }
                <div style={estilo}>
                <Navbar bg="light" expand="lg">
                    <Navbar.Brand size="sm" href="#home">Escuelas</Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="mr-auto" size="sm">
                            <Button size="sm" variant="success" onClick={this.nuevaEscuela}>+</Button>
                        </Nav>
                        <Form inline onSubmit={this.onSubmit} >
                            <Form.Label size="sm">Tipo</Form.Label>&nbsp;
                            <Form.Control size="sm" as="select" name="tipo" value={this.state.tipo} onChange={this.onChange}>
                                {this.state.tipos.map((i,k) =>(
                                    <option key={i.I} value={i.I}>{i.N}</option>    
                                ))}
                            </Form.Control>
                            &nbsp;
                            <FormControl size="sm" type="text" placeholder="Buscar" name="nombre" onChange={this.onChange} className="mr-sm-2" />
                            <Button size="sm" variant="success" onClick={this.onBntBuscarClick}>Buscar</Button>
                        </Form>
                    </Navbar.Collapse>
                </Navbar>
                <div style={{ height: 4 }}></div>
                    <EscuelaTable escuelas={this.state.escuelas} height={500} width={990} onRowClick={this.cargarEscuela} />
                </div>
                <EscuelaForm tipos={this.state.tipos} visible={this.state.formVisible} escuela={this.state.escuela} onClose={this.onFormClose} clave={this.state.clave} guardar={this.guardarEscuela}/>
            </Container>
        );
    }
}

class EscuelaTable extends React.Component {
    onRowClick = (cve) => {
        this.props.onRowClick(cve);
    }
    render() {
        let { escuelas } = this.props;
        return (
            <div style={{ height: this.props.height, width:this.props.width, overflow: 'auto' }}>
            <Table striped bordered hover size="sm">
                <thead>
                    <tr>
                        <th style={{width:100}}>Clave</th>
                        <th>Nombre</th>
                        <th>Direccion</th>
                        <th>Tipo</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {escuelas.map((esc, i) => (
                        <EscuelaRow key={i} escuela={esc} onRowClick={this.onRowClick} />
                    ))}
                </tbody>
                </Table>
            </div>
        );
    }
}

class EscuelaRow extends React.Component{
    onClick = (e) =>{
        this.props.onRowClick(this.props.escuela.Clave);
    }
    render(){
        let {escuela} = this.props;
        return (
        <tr>
            <td>{escuela.Clave}</td>
            <td>{escuela.Nombre}</td>
            <td>{escuela.Direccion}</td>
            <td>{escuela.Tipo}</td>
            <td>
                <Button variant="primary" size="sm" onClick={this.onClick}>
                    <img src="/imagenes/pencil.png" alt="editar" title="editar"/>
                </Button>
            </td>
        </tr>
        );
    }
}

class EscuelaForm extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            //...this.props.alumno,
            escuela:this.props.escuela,
        }
    }
    componentWillReceiveProps(newProps){
        this.setState({
            escuela:newProps.escuela
        });
    }
    onChange = (e) => {
        const esc = {
            ...this.state.escuela,
            [e.target.name] : e.target.value
        };
        this.setState({escuela:esc});
    }
    guardar = () =>{
        const MySwal = withReactContent(Swal)
        MySwal.fire({
            title: 'Confirme',
            text: "¿ Desea guardar estos datos ?",
            type: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'No',
            confirmButtonText: 'Si'
          }).then((result) => {
            if (result.value) {
                this.props.guardar(this.state.escuela);
            }
          })        
    }
    render() {
        const estilo = { display: this.props.visible ? 'inline' : 'none' };
        if(this.props.escuela === undefined)
            return null;
        if(Object.keys(this.props.escuela).length === 0)
            return null;
        return (
            <Container style={estilo}>
                <Form>
                    <div>
                        <Form.Row>
                            <Form.Group as={Col} md="2">
                                <Form.Label>Clave</Form.Label>
                                <Form.Control size="sm" type="text" name="Clave" value={this.state.escuela.Clave} />
                            </Form.Group>
                            <Form.Group as={Col}>
                                <Form.Label>Nombre</Form.Label>
                                <Form.Control size="sm" type="text" name="Nombre" value={this.state.escuela.Nombre} onChange={this.onChange} />
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col}>
                                <Form.Label>Direccion</Form.Label>
                                <Form.Control size="sm" type="text" name="Direccion" value={this.state.escuela.Direccion} onChange={this.onChange} />
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col}>
                                <Form.Label>Director</Form.Label>
                                <Form.Control size="sm" type="text" name="Director" value={this.state.escuela.Director} onChange={this.onChange} />
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col}>
                                <Form.Label>Telefono</Form.Label>
                                <Form.Control size="sm" type="text" name="Telefono" value={this.state.escuela.Telefono} onChange={this.onChange} />
                            </Form.Group>
                            <Form.Group as={Col}>
                                <Form.Label>Celular</Form.Label>
                                <Form.Control size="sm" type="text" name="Celular" value={this.state.escuela.Celular} onChange={this.onChange} />
                            </Form.Group>
                            <Form.Group as={Col} md="3">
                                <Form.Label>Turno</Form.Label>
                                <Form.Control size="sm" as="select" name="Turno" value={this.state.escuela.Turno} onChange={this.onChange}>
                                    <option value="MATUTINO">MATUTINO</option>
                                    <option value="VESPERTINO">VESPERTINO</option>
                                    <option value="NOCTURNO">NOCTURNO</option>
                                    <option value="DISCONTINUO">DISCONTINUO</option>
                                    <option value="MAT. Y VESP.">MAT. Y VESP.</option>
                                    <option value="MAT., VESP. Y NOCT.">MAT., VESP. Y NOCT.</option>
                                </Form.Control>
                            </Form.Group>
                            <Form.Group as={Col} md="5">
                                <Form.Label>Tipo</Form.Label>
                                <Form.Control size="sm" as="select" name="IdTipo" value={this.state.escuela.IdTipo} onChange={this.onChange}>
                                    {this.props.tipos.map((i, k) =>
                                        <option key={i.I} value={i.I}>{i.N}</option>
                                    )}
                                </Form.Control>
                            </Form.Group>
                        </Form.Row>
                    </div>
                </Form>
                <br />
                <Button variant="primary" type="submit" onClick={this.guardar}>Aceptar</Button>
                {'  '}
                <Button variant="danger" onClick={this.props.onClose}>Cancelar</Button>
            </Container>
        );
    }
}