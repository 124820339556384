import React from 'react';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Form from 'react-bootstrap/Form';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Spinner from 'react-bootstrap/Spinner';
import Modal from 'react-bootstrap/Modal';
import Swal from 'sweetalert2'

export default class ExamenesAlumnos extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            examen:0,
            fecUltEx:0,
            asistencias:0,
            faltas:0,
            profesor:'',
            sinodal:'',
            show:false,
            cargando:true,
            formVisible: false,
            alumno:0,
            doyang:-1,
            resultado:'X',
            resultados:[{key:'X',text : 'PENDIENTE'},{key:'S',text : 'APROBADO'},{key:'N',text : 'NO APROBADO'}],
            doyangs:[],
            examenes: [],
            alumnos:[]
        };
        this.cargarCatalogos();
    }
    cargarCatalogos = async() =>{
        let res = await fetch(`${this.props.urlws}/doyang/listaUsuario/${this.props.usuario.id}`);
        const du = this.props.usuario.doyang;
        const dys = await res.json();
        const doyangs = dys;// .filter((d)=> du === 0 || du === d.Id);
        const doyang = (du > 0) ? du : -1;
        this.setState({ doyangs,doyang,cargando:false });
    }
    cargarExamenes = async () => {
        this.setState({cargando:true});
        const url = `${this.props.urlws}/examen/lista/${this.state.doyang}?fecIni=2000-01-01&fecFin=2050-01-01`;
        const resp = await fetch(url);
        const examenes = await resp.json();
        this.setState({cargando:false, examenes });
    }
    cargarAlumnos = async () => {
        this.setState({cargando:true});
        const url = `${this.props.urlws}/examen/asignados/${this.state.doyang}?examen=${this.state.examen}`;
        const resp = await fetch(url);
        const alumnos = await resp.json();
        this.setState({cargando:false, alumnos });
    }
    editar = async () => {
        this.setState({cargando:true});
        const url = `${this.props.urlws}/examen/editar/?exa=${this.state.examen}&alu=${this.state.alumno}&fec=${this.state.fecUltEx}&asis=${this.state.asistencias}&faltas=${this.state.faltas}&res=${this.state.resultado}&prof=${this.state.profesor}&sin=${this.state.sinodal}`;
        const resp = await fetch(url);
        const res = await resp.text();
        this.setState({cargando:false, show : res !== "ok"});
        if(res === "ok")
           Swal.fire('Atencion','Cambio registrado','success');
        else
           Swal.fire('Atencion',res,'error');
    }
    asignar = (alu) => {
        const url = `${this.props.urlws}/examen/solicitud?doyang=${this.state.doyang}&pago=${alu.Numero}`;
        window.open(url, '', '');
    }
    handleClose  = () =>{
        this.setState({show:false});
    }
    handleShow  = (alu) =>{
        console.log(alu);
        this.setState({show:true, alumno:alu.Alumno,fecUltEx:alu.FecUltEx,asistencias:alu.Asistencias, faltas:alu.Faltas, resultado:alu.Res,profesor:alu.Profesor, sinodal:alu.Sinodal});
    }
    onSubmit = (e) => {
        e.preventDefault();
        this.cargarAlumnos();
    }
    onSubmitEx = (e) => {
        e.preventDefault();
        this.editar();
    }
    onChange = (e) => {
        const campo = e.target.name;
        this.setState({ [campo] : e.target.value},() =>{
            if(campo === "doyang")
                this.cargarExamenes(); 
        });
    }
    onBntBuscarClick = () =>{
        this.cargarAlumnos();
    }    
    render(){
        let lugar = '';
        let sinodal = '';
        for(let k = 0; k < this.state.examenes.length; k++)
           if(this.state.examenes[k].Numero === this.state.examen){
               lugar = this.state.examenes[k].Lugar;
               sinodal = this.state.examenes[k].Sinodal;
           }
        return(
            <Container >
                {this.state.cargando ?
                <div className="loader">
                    <Spinner animation="border" variant="primary"/>
                </div>
                : 
                null
                }
            <div>
            <Navbar bg="light" expand="lg">
                <Navbar.Brand size="sm" href="#home">&nbsp;</Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="mr-auto" size="sm">
                    </Nav>
                    <Form inline onSubmit={this.onSubmit} >
                            <Form.Label size="sm">Doyang</Form.Label>&nbsp;
                            <Form.Control size="sm" as="select" name="doyang" value={this.state.doyang} onChange={this.onChange}>
                                <option key={-1} value={-1}>Especifique</option>    
                                {this.state.doyangs.map((i,k) =>(
                                    <option key={i.Id} value={i.Id}>{i.Nombre}</option>    
                                ))}
                            </Form.Control>
                            <Form.Label size="sm">Examen</Form.Label>&nbsp;
                            <Form.Control size="sm" as="select" name="examen" value={this.state.examen} onChange={this.onChange} style={{width:300}}>
                                <option key={-1} value={-1}>Especifique</option>    
                                {this.state.examenes.map((i,k) =>(
                                    <option key={i.Numero} value={i.Numero}>{i.Fecha} - {i.Profesor}</option>
                                ))}
                            </Form.Control>
                        &nbsp;&nbsp;
                        <Button size="sm" variant="success" onClick={this.onBntBuscarClick}>Aceptar</Button>
                    </Form>
                </Navbar.Collapse>
            </Navbar>
            <div style={{ height: 4 }}></div>
            <div className="row">
                <div className="col">
                    <span>Sinodal</span>
                    &nbsp;
                    <span style={{fontWeight:'bold'}}>{sinodal}</span>
                </div>
                <div className="col">
                    <span>Lugar</span>
                    &nbsp;
                    <span style={{fontWeight:'bold'}}>{lugar}</span>
                </div>
            </div>
            <div style={{ height: 4 }}></div>
            <AlumnosTable alumnos={this.state.alumnos} height={500} width={990} onRowClick={this.asignar} handleShow={this.handleShow}/>
            </div>
            <Modal show={this.state.show} onHide={this.handleClose}>
                    <Modal.Body>
                        <form onSubmit={this.onSubmitEx}>
                            <div className="row">
                                <div className="col">
                                    <div className="form-group" size="sm">
                                        <label>Fec.Ult. examen</label>
                                        <Form.Control size="sm" type="date" name="fecUltEx" value={this.state.fecUltEx} onChange={this.onChange} />
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="form-group" size="sm">
                                        <label>Asistencias</label>
                                        <Form.Control size="sm" type="text" name="asistencias" value={this.state.asistencias} onChange={this.onChange} />
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="form-group" size="sm">
                                        <label>Faltas</label>
                                        <Form.Control size="sm" type="text" name="faltas" value={this.state.faltas} onChange={this.onChange} />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <div className="form-group" size="sm">
                                        <label>Profesor</label>
                                        <Form.Control size="sm" type="text" name="profesor" value={this.state.profesor} onChange={this.onChange} />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <div className="form-group" size="sm">
                                        <label>Sinodal</label>
                                        <Form.Control size="sm" type="text" name="sinodal" value={this.state.sinodal} onChange={this.onChange} />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <Form.Control size="sm" as="select" name="resultado" value={this.state.resultado} onChange={this.onChange} style={{ width: 300 }}>
                                        <option key={-1} value={-1}>Especifique</option>
                                        {this.state.resultados.map((i, k) => (
                                            <option key={i.key} value={i.key}>{i.text}</option>
                                        ))}
                                    </Form.Control>
                                </div>
                            </div>
                            <br/>
                            <input type="submit" className="btn btn-success" value="Guardar" />
                        </form>
                    </Modal.Body>
               </Modal>
        </Container>
        );
    }
}
class AlumnosTable extends React.Component {
    onRowClick = (alu) => {
        this.props.onRowClick(alu);
    }
    render() {
        let { alumnos } = this.props;
        return (
            <div style={{ height: this.props.height, width:this.props.width, overflow: 'auto' }}>
            <Table striped bordered hover size="sm">
                <thead>
                    <tr>
                        <th style={{width:100}}>Pago</th>
                        <th>Tipo</th>
                        <th>Clave</th>
                        <th>Nombre</th>
                        <th></th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {alumnos.map((alu, i) => (
                        <AlumnoRow key={i} alumno={alu} onRowClick={this.onRowClick} handleShow={this.props.handleShow}/>
                    ))}
                </tbody>
                </Table>
            </div>
        );
    }
}

class AlumnoRow extends React.Component{
    onClick = (e) =>{
        this.props.onRowClick(this.props.alumno);
    }
    onEdit = (e) =>{
        this.props.handleShow(this.props.alumno);
    }
    render(){
        let {alumno} = this.props;
        return (
        <tr>
            <td>{alumno.Numero}</td>
            <td>{alumno.Tipo}</td>
            <td>{alumno.Clave}</td>
            <td>{alumno.Nombre}</td>
            <td>
                {alumno.C ? null :
                    <Button variant="primary" size="sm" onClick={this.onEdit}>
                        Editar
                    </Button>
                }
            </td>
            <td>
                <Button variant="primary" size="sm" onClick={this.onClick}>
                    Solicitud
                </Button>
            </td>
        </tr>
        );
    }
}
