import React from 'react';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';

export default class Buscador extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tipo:props.tipo,
            nombre: '',
            datos: []
        };
    }
    componentDidMount(){
        const estado = localStorage.getItem("buscador");
        if(estado === null)
            return;
        const estadoJson = JSON.parse(estado);
        if(estadoJson.tipo === this.props.tipo)
            this.setState(estadoJson);
        if(this.props.tipo === "eventos")
            this.cargar();
    }
    componentWillUnmount(){
        const estado = JSON.stringify(this.state);
        localStorage.setItem("buscador", estado);
    }
    
    onChange = e => {
        this.setState({ nombre: e.target.value });
    }
    onKeyPress = e => {
        if (e.which === 13)
            this.cargar();
    }
    onClick = e => {
        this.cargar();
    }
    onRowClick = (id,nom) => {
        if(this.props.onSelect !== null){
            this.props.onSelect(id,nom);
            document.body.click();
        }
    }
    cargar = async () => {
        let url = "";
        if(this.props.tipo === "alumno")
            url=`${this.props.urlws}&n=${this.state.nombre}&s=0`;
        if(this.props.tipo === "eventos")
            url = this.props.urlws + "/alumno/eventos/" + this.props.clave;
        const res = await fetch(url);
        const datos = await res.json();
        this.setState({ datos });
    }
    render() {
        return (
            <React.Fragment>
                <div className="row">
                    <div className="col-7">
                        <input type="text" className="form-control" placeholder="concepto" name="nombre" value={this.state.nombre} onChange={this.onChange} onKeyPress={this.onKeyPress} />
                    </div>
                    <div className="col"><Button onClick={this.onClick}>Buscar</Button></div>
                </div>
                <div style={{height:3}}></div>
                <div style={{ height: 300, overflow: 'auto' }}>
                    <Table striped bordered hover size="sm">
                        <thead>
                            <tr>
                                {this.props.columnas.map((i)=>{
                                    return <th>{i}</th>
                                })}
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.datos.map((item, index) => {
                                return (
                                    <tr key={index} onClick={() => this.onRowClick(item.Clave, item.Nombre)}>
                                        {this.props.mapearCampos(item)}
                                    </tr>
                                )
                            })}
                        </tbody>
                    </Table>
                </div>
            </React.Fragment>
        )
    }
}