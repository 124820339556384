import React from 'react';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Form from 'react-bootstrap/Form';
import Table from 'react-bootstrap/Table';
import Col from 'react-bootstrap/Col';
import FormControl from 'react-bootstrap/FormControl';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Spinner from 'react-bootstrap/Spinner';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

export default class Articulos extends React.Component {
    artiInicial = {Clave:'#',Nombre:'',PrecioProf:0,PrecioPub:0,Iva:0};

    constructor(props) {
        super(props);
        this.state = {
            cargando:true,
            formVisible: false,
            clave: '',
            nombre:'',
            articulo:{},
            articulos: []
        };
        this.cargarArticulos();
    }
    onFormClose = () => {
        this.setState({
            formVisible: false
        });
    }
    cargarArticulos = async () => {
        this.setState({cargando:true});
        const url = `${this.props.urlws}/articulo/lista?nom=${this.state.nombre}`;
        const resp = await fetch(url);
        const articulos = await resp.json();
        this.setState({cargando:false, articulos });
    }
    onSubmit = (e) => {
        e.preventDefault();
        this.cargarArticulos();
    }
    onChange = (e) => {
        this.setState({ [e.target.name] : e.target.value});
    }
    onBntBuscarClick = () =>{
        this.cargarArticulos();
    }    
    cargarArticulo = async (cve) => {
        this.setState({cargando:true});
        const url = this.props.urlws + "/articulo/cargar/" + cve;
        const resp = await fetch(url);
        const articulo = await resp.json();
        this.setState({
            cargando:false,
            formVisible: true,
            clave: cve,
            articulo
        });
    }
    guardarArticulo = async(articulo) =>{
        const url = this.props.urlws +"/articulo/guardar";
        this.setState({cargando:true});
        const json = JSON.stringify(articulo);
        const resp = await fetch(url,{
            method: 'POST', // or 'PUT'
            body: json // data can be `string` or {object}!
          });
        const res = await resp.text();
        this.setState({cargando:false});
        if(res === "ok"){
            Swal.fire('Atencion','Datos guardados','success');
            this.onFormClose();
        }
        else
            Swal.fire('Atencion',res,'error');
    }
    nuevoArticulo = () => {
        let esc = {...this.artiInicial};
        this.setState({
            formVisible: true,
            clave: 'NUEVO',
            articulo:esc
        });
    }
    render() {
        const estilo = { display: this.state.formVisible ? 'none' : 'inline' };
        return (
            <Container >
                {this.state.cargando ?
                <div className="loader">
                    <Spinner animation="border" variant="primary"/>
                </div>
                : 
                null
                }
                <div style={estilo}>
                <Navbar bg="light" expand="lg">
                    <Navbar.Brand size="sm" href="#home">Articulos</Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="mr-auto" size="sm">
                            <Button size="sm" variant="success" onClick={this.nuevoArticulo}>+</Button>
                        </Nav>
                        <Form inline onSubmit={this.onSubmit} >
                            <Form.Label size="sm">Tipo</Form.Label>&nbsp;
                            <FormControl size="sm" type="text" placeholder="Buscar" name="nombre" onChange={this.onChange} className="mr-sm-2" />
                            &nbsp;
                            <Button size="sm" variant="success" onClick={this.onBntBuscarClick}>Buscar</Button>
                        </Form>
                    </Navbar.Collapse>
                </Navbar>
                <div style={{ height: 4 }}></div>
                    <ArticuloTable articulos={this.state.articulos} height={500} width={990} onRowClick={this.cargarArticulo} />
                </div>
                <ArticuloForm visible={this.state.formVisible} articulo={this.state.articulo} onClose={this.onFormClose} clave={this.state.clave} guardar={this.guardarArticulo}/>
            </Container>
        );
    }
}

class ArticuloTable extends React.Component {
    onRowClick = (cve) => {
        this.props.onRowClick(cve);
    }
    render() {
        let { articulos } = this.props;
        return (
            <div style={{ height: this.props.height, width:this.props.width, overflow: 'auto' }}>
            <Table striped bordered hover size="sm">
                <thead>
                    <tr>
                        <th style={{width:100}}>Clave</th>
                        <th>Nombre</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {articulos.map((esc, i) => (
                        <ArticuloRow key={i} articulo={esc} onRowClick={this.onRowClick} />
                    ))}
                </tbody>
                </Table>
            </div>
        );
    }
}

class ArticuloRow extends React.Component{
    onClick = (e) =>{
        this.props.onRowClick(this.props.articulo.Clave);
    }
    render(){
        let {articulo} = this.props;
        return (
        <tr>
            <td>{articulo.Clave}</td>
            <td>{articulo.Nombre}</td>
            <td>
                <Button variant="primary" size="sm" onClick={this.onClick}>
                    <img src="/imagenes/pencil.png" alt="editar" title="editar"/>
                </Button>
            </td>
        </tr>
        );
    }
}

class ArticuloForm extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            //...this.props.alumno,
            articulo:this.props.articulo,
        }
    }
    componentWillReceiveProps(newProps){
        this.setState({
            articulo:newProps.articulo
        });
    }
    onChange = (e) => {
        const esc = {
            ...this.state.articulo,
            [e.target.name] : e.target.value
        };
        this.setState({articulo:esc});
    }
    guardar = () =>{
        const MySwal = withReactContent(Swal)
        MySwal.fire({
            title: 'Confirme',
            text: "¿ Desea guardar estos datos ?",
            type: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'No',
            confirmButtonText: 'Si'
          }).then((result) => {
            if (result.value) {
                this.props.guardar(this.state.articulo);
            }
          })        
    }
    render() {
        const estilo = { display: this.props.visible ? 'inline' : 'none' };
        if(this.props.articulo === undefined)
            return null;
        if(Object.keys(this.props.articulo).length === 0)
            return null;
        return (
            <Container style={estilo}>
                <h3>Articulos</h3>
                <Form>
                    <div>
                        <Form.Row>
                            <Form.Group as={Col} md="2">
                                <Form.Label>Clave</Form.Label>
                                <Form.Control size="sm" type="text" name="Clave" value={this.state.articulo.Clave} />
                            </Form.Group>
                            <Form.Group as={Col}>
                                <Form.Label>Nombre</Form.Label>
                                <Form.Control size="sm" type="text" name="Nombre" value={this.state.articulo.Nombre} onChange={this.onChange} />
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col}>
                                <Form.Label>Precio Prof.</Form.Label>
                                <Form.Control size="sm" type="text" name="PrecioProf" value={this.state.articulo.PrecioProf} onChange={this.onChange} />
                            </Form.Group>
                            <Form.Group as={Col}>
                                <Form.Label>Precio Pub.</Form.Label>
                                <Form.Control size="sm" type="text" name="PrecioPub" value={this.state.articulo.PrecioPub} onChange={this.onChange} />
                            </Form.Group>
                            <Form.Group as={Col}>
                                <Form.Label>Tasa IVA</Form.Label>
                                <Form.Control size="sm" type="text" name="Iva" value={this.state.articulo.Iva} onChange={this.onChange} />
                            </Form.Group>
                        </Form.Row>
                    </div>
                </Form>
                <br />
                <Button variant="primary" type="submit" onClick={this.guardar}>Aceptar</Button>
                {'  '}
                <Button variant="danger" onClick={this.props.onClose}>Cancelar</Button>
            </Container>
        );
    }
}