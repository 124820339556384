import React from 'react';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Form from 'react-bootstrap/Form';
import Table from 'react-bootstrap/Table';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Spinner from 'react-bootstrap/Spinner';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

export default class Doyangs extends React.Component {
    doyangInicial = {Id:0,Nombre:'',Director:'',Direccion:'',Telefono:'',Celular:'',TelCasa:'',Email:'',Sinodal:'',Plaza:0, Activo:true, Orden:999, FechaBaja:'',Tipo:'D',Local:'S',CostoExamen:0,CostoExamenPre:0};

    constructor(props) {
        super(props);
        this.state = {
            cargando:true,
            formVisible: false,
            id: 0,
            nombre:'',
            doyang:{},
            plazas:[],
            doyangs: []
        };
        this.cargarCatalogos();
        this.cargarDoyangs();
    }
    onFormClose = () => {
        this.setState({
            formVisible: false
        });
    }
    cargarCatalogos = async() =>{
        let res = await fetch(this.props.urlws + "/home/plazas");
        const plazas = await res.json();
        this.setState({ plazas });
    }

    cargarDoyangs = async () => {
        this.setState({cargando:true});
        const url = this.props.urlws + "/doyang/lista";
        const resp = await fetch(url);
        const doyangs = await resp.json();
        this.setState({cargando:false, doyangs });
    }
    onSubmit = (e) => {
        e.preventDefault();
        this.cargarDoyangs();
    }
    onChange = (e) => {
        this.setState({ [e.target.name] : e.target.value});
    }
    onBntBuscarClick = () =>{
        this.cargarDoyangs();
    }    
    cargarDoyang = async (cve) => {
        this.setState({cargando:true});
        const url = this.props.urlws +"/doyang/cargar/" + cve;
        const resp = await fetch(url);
        const doyang = await resp.json();
        this.setState({
            cargando:false,
            formVisible: true,
            id: cve,
            doyang
        });
    }
    guardarDoyang = async(doyang) =>{
        const url = this.props.urlws + "/doyang/guardar";
        this.setState({cargando:true});
        const json = JSON.stringify(doyang);
        const resp = await fetch(url,{
            method: 'POST', // or 'PUT'
            body: json // data can be `string` or {object}!
          });
        const res = await resp.text();
        this.setState({cargando:false});
        if(res === "ok"){
            Swal.fire('Atencion','Datos guardados','success');
            this.onFormClose();
            this.cargarDoyangs();
        }
        else
            Swal.fire('Atencion',res,'error');
    }
    nuevoDoyang = () => {
        let esc = {...this.doyangInicial};
        this.setState({
            formVisible: true,
            id : 0,
            doyang:esc
        });
    }
    render() {
        const estilo = { display: this.state.formVisible ? 'none' : 'inline' };
        return (
            <Container >
                {this.state.cargando ?
                <div className="loader">
                    <Spinner animation="border" variant="primary"/>
                </div>
                : 
                null
                }
                <div style={estilo}>
                <Navbar bg="light" expand="lg">
                    <Navbar.Brand size="sm" href="#home">Doyangs</Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="mr-auto" size="sm">
                            <Button size="sm" variant="success" onClick={this.nuevoDoyang}>+</Button>
                        </Nav>
                    </Navbar.Collapse>
                </Navbar>
                <div style={{ height: 4 }}></div>
                    <DoyangTable doyangs={this.state.doyangs} height={500} width={990} onRowClick={this.cargarDoyang} />
                </div>
                <DoyangForm plazas={this.state.plazas} visible={this.state.formVisible} doyang={this.state.doyang} onClose={this.onFormClose} clave={this.state.id} guardar={this.guardarDoyang}/>
            </Container>
        );
    }
}

class DoyangTable extends React.Component {
    onRowClick = (cve) => {
        this.props.onRowClick(cve);
    }
    render() {
        let { doyangs } = this.props;
        return (
            <div style={{ height: this.props.height, width:this.props.width, overflow: 'auto' }}>
            <Table striped bordered hover size="sm">
                <thead>
                    <tr>
                        <th style={{width:100}}>Clave</th>
                        <th>Nombre</th>
                        <th>Direccion</th>
                        <th>Activo</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {doyangs.map((esc, i) => (
                        <DoyangRow key={i} doyang={esc} onRowClick={this.onRowClick} />
                    ))}
                </tbody>
                </Table>
            </div>
        );
    }
}

class DoyangRow extends React.Component{
    onClick = (e) =>{
        this.props.onRowClick(this.props.doyang.Id);
    }
    render(){
        let {doyang} = this.props;
        return (
        <tr>
            <td>{doyang.Id}</td>
            <td>{doyang.Nombre}</td>
            <td>{doyang.Direccion}</td>
            <td>
                <Form.Check inline type="checkbox" checked={doyang.Activo}/>
            </td>
            <td>
                <Button variant="primary" size="sm" onClick={this.onClick}>
                    <img src="/imagenes/pencil.png" alt="editar" title="editar"/>
                </Button>
            </td>
        </tr>
        );
    }
}

class DoyangForm extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            //...this.props.alumno,
            doyang:this.props.doyang,
        }
    }
    componentWillReceiveProps(newProps){
        this.setState({
            doyang:newProps.doyang
        });
    }
    onChange = (e) => {
        const esc = {
            ...this.state.doyang,
            [e.target.name] : e.target.value
        };
        this.setState({doyang:esc});
    }
    onChecked = (e) => {
        const esc = {
            ...this.state.doyang,
            [e.target.name] : e.target.checked
        };
        this.setState({doyang:esc});
    }    
    guardar = () =>{
        const MySwal = withReactContent(Swal)
        MySwal.fire({
            title: 'Confirme',
            text: "¿ Desea guardar estos datos ?",
            type: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'No',
            confirmButtonText: 'Si'
          }).then((result) => {
            if (result.value) {
                this.props.guardar(this.state.doyang);
            }
          })        
    }
    render() {
        const estilo = { display: this.props.visible ? 'inline' : 'none' };
        if(this.props.doyang === undefined)
            return null;
        if(Object.keys(this.props.doyang).length === 0)
            return null;
        return (
            <Container style={estilo}>
                <Form>
                    <div>
                        <Form.Row>
                            <Form.Group as={Col} md="2">
                                <Form.Label>Id</Form.Label>
                                <Form.Control size="sm" type="text" name="Id" value={this.state.doyang.Id} />
                            </Form.Group>
                            <Form.Group as={Col}>
                                <Form.Label>Nombre</Form.Label>
                                <Form.Control size="sm" type="text" name="Nombre" value={this.state.doyang.Nombre} onChange={this.onChange} />
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col}>
                                <Form.Label>Direccion</Form.Label>
                                <Form.Control size="sm" type="text" name="Direccion" value={this.state.doyang.Direccion} onChange={this.onChange} />
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col}>
                                <Form.Label>Director</Form.Label>
                                <Form.Control size="sm" type="text" name="Director" value={this.state.doyang.Director} onChange={this.onChange} />
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col}>
                                <Form.Label>Telefono</Form.Label>
                                <Form.Control size="sm" type="text" name="Telefono" value={this.state.doyang.Telefono} onChange={this.onChange} />
                            </Form.Group>
                            <Form.Group as={Col}>
                                <Form.Label>Celular</Form.Label>
                                <Form.Control size="sm" type="text" name="Celular" value={this.state.doyang.Celular} onChange={this.onChange} />
                            </Form.Group>
                            <Form.Group as={Col}>
                                <Form.Label>Tel.Casa</Form.Label>
                                <Form.Control size="sm" type="text" name="TelCasa" value={this.state.doyang.TelCasa} onChange={this.onChange} />
                            </Form.Group>
                            <Form.Group as={Col} md="5">
                                <Form.Label>Plaza</Form.Label>
                                <Form.Control size="sm" as="select" name="Plaza" value={this.state.doyang.Plaza} onChange={this.onChange}>
                                    {this.props.plazas.map((i, k) =>
                                        <option key={i.I} value={i.I}>{i.N}</option>
                                    )}
                                </Form.Control>
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col} md="2">
                                <Form.Label>Examen</Form.Label>
                                <Form.Control size="sm" type="text" name="CostoExamen" value={this.state.doyang.CostoExamen} onChange={this.onChange} />
                            </Form.Group>
                            <Form.Group as={Col} md="2">
                                <Form.Label>Examen Pre</Form.Label>
                                <Form.Control size="sm" type="text" name="CostoExamenPre" value={this.state.doyang.CostoExamenPre} onChange={this.onChange} />
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col} md="2">
                                <Form.Label>Tipo</Form.Label>
                                <Form.Control size="sm" as="select" name="Tipo" value={this.state.doyang.Tipo} onChange={this.onChange}>
                                    <option value="D">Doyang</option>
                                    <option value="C">Club</option>
                                </Form.Control>
                            </Form.Group>
                            <Form.Group as={Col} md="2">
                                <Form.Label>Tipo</Form.Label>
                                <Form.Control size="sm" as="select" name="Local" value={this.state.doyang.Local} onChange={this.onChange}>
                                    <option value="L">Local</option>
                                    <option value="F">Foraneo</option>
                                </Form.Control>
                            </Form.Group>
                            <Form.Group as={Col}>
                                <Form.Label>Orden</Form.Label>
                                <Form.Control size="sm" type="text" name="Orden" value={this.state.doyang.Orden} onChange={this.onChange} />
                            </Form.Group>
                            <Form.Group as={Col}>
                                <br/>
                                <Form.Check inline label="Activo" type="checkbox" name="Activo" checked={this.state.doyang.Activo} onChange={this.onChecked} />
                            </Form.Group>
                        </Form.Row>

                    </div>
                </Form>
                <br />
                <Button variant="primary" type="submit" onClick={this.guardar}>Aceptar</Button>
                {'  '}
                <Button variant="danger" onClick={this.props.onClose}>Cancelar</Button>
            </Container>
        );
    }
}