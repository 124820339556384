import React from 'react';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Spinner from 'react-bootstrap/Spinner';
import AlumnosTable from './AlumnosTable';
import AlumnoForm from './AlumnoForm';
import Swal from 'sweetalert2'

export default class Alumnos extends React.Component {
    alumnoInicial = {Clave:"NUEVO",Nombre:"",FechaNac:"",FechaIng:new Date().yyyymmdd('-'),Direccion:"",Escuela:"",Telefono:"",Celular:"",Sexo:"",EdoCivil:0,Peso:0,Estatura:0,Padre:"",OcupPadre:"",TelPadre:"",EmpPadre:"",PtoPadre:"",CelPadre:"",Madre:"",OcupMadre:"",TelMadre:"",EmpMadre:"",PtoMadre:"",CelMadre:"",Grado:"",SGrado:"",Grupo:"",Puntos:0,MatMdk:"",Horario:0,Promocion:"",Recomendo:"",TipoPub:0,Lun:0,Mar:0,Mie:0,Jue:0,Vie:0,Sab:0,Dom:0,Activo:1,DEmpresa:"",DPuesto:"",DOcupacion:"",DDireccion:"",DCelular:"",DTelefono:"",DResponsable:"",FecBaja:"",Becado:false,Doyang:"",Catego:"",IdEscuela:0,IdDoyang:0,Clave2:"",AIngreso:0,Recomendado:"",IdPromocion:0,EnteradoPorOtro:"",GradoActual:0,SubGradoActual:0,CuotaEspecial:false,FechaUltEx:"",Faltas:0,Asistencias:0,Id:0};
    constructor(props) {
        super(props);
        this.state = {
            cargando:true,
            doyang:-1,
            formVisible: false,
            matricula: '',
            nombre:'',
            activo:0,
            alumno:{},
            doyangs:[],
            alumnos: [],
            conteo:0
        };
        this.cargarCatalogos();
        this.cargarAlumnos();
    }
    onFormClose = () => {
        this.setState({
            formVisible: false
        });
    }
    cargarCatalogos = async() =>{
        let res = await fetch(`${this.props.urlws}/doyang/listaUsuario/${this.props.usuario.id}`);
        const du = this.props.usuario.doyang;
        const dys = await res.json();
        const doyangs = dys; //.filter((d)=> du === 0 || du === d.Id);
        const doyang = (du > 0) ? du : -1;
        this.setState({ doyangs,doyang });
    }
    cargarAlumnos = async () => {
        this.setState({cargando:true});
        const url = `${this.props.urlws}/alumno/lista?d=${this.state.doyang}&n=${this.state.nombre}&s=${this.state.activo}`;
        const resp = await fetch(url);
        const alumnos = await resp.json();
        const conteo = alumnos.length;
        this.setState({cargando:false, alumnos, conteo });
    }
    onSubmit = (e) => {
        e.preventDefault();
        this.cargarAlumnos();
    }
    onChange = (e) => {
        this.setState({ [e.target.name] : e.target.value});
    }
    onBntBuscarClick = () =>{
        this.cargarAlumnos();
    }    
    cargarAlumno = async (mat,id) => {
        this.setState({cargando:true});
        const url = this.props.urlws + "/alumno/cargar/" + id;
        const resp = await fetch(url);
        const alumno = await resp.json();
        this.setState({
            cargando:false,
            formVisible: true,
            matricula: mat,
            alumno
        });
    }
    guardarAlumno = async(alumno) =>{
        const url = this.props.urlws + "/alumno/guardar";
        //const url = "http://localhost:1673/alumno/guardar";
        if(alumno.IdDoyang === 0)
           alumno.IdDoyang = this.state.doyang;
        this.setState({cargando:true,alumno});
        const json = JSON.stringify(alumno);
        const resp = await fetch(url,{
            method: 'POST', // or 'PUT'
            body: json // data can be `string` or {object}!
          });
        const res = await resp.text();
        this.setState({cargando:false});
        if(res === "ok"){
            Swal.fire('Atencion','Datos guardados','success');
            this.onFormClose();
        }
        else
            Swal.fire('Atencion',res,'error');
    }
    nuevoAlumno = () => {
        if(this.state.doyang < 0){
            Swal.fire('Atencion','Especifique el Doyang','error');
            return;
        }
        let alu = {...this.alumnoInicial};
        this.setState({
            formVisible: true,
            matricula: 'NUEVO',
            alumno:alu
        });
    }
    render() {
        const estilo = { display: this.state.formVisible ? 'none' : 'inline' };
        return (
            <Container >
                {this.state.cargando ?
                <div className="loader">
                    <Spinner animation="border" variant="primary"/>
                </div>
                : 
                null
                }
                <div style={estilo}>
                <Navbar bg="light" expand="lg">
                    <Navbar.Brand size="sm" href="#home">Alumnos</Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="mr-auto" size="sm">
                            <Button size="sm" variant="success" onClick={this.nuevoAlumno}>+</Button>
                        </Nav>
                        <Form inline onSubmit={this.onSubmit} >
                                <Form.Label size="sm">Doyang</Form.Label>&nbsp;
                                <Form.Control size="sm" as="select" name="doyang" value={this.state.doyang} onChange={this.onChange}>
                                    <option key={-1} value={-1}>Especifique</option>    
                                    {this.state.doyangs.map((i,k) =>(
                                        <option key={i.Id} value={i.Id}>{i.Nombre}</option>    
                                    ))}
                                </Form.Control>
                            &nbsp;
                            <Form.Label size="sm">Status</Form.Label>&nbsp;
                            <Form.Control size="sm" as="select" name="activo" value={this.state.activo} onChange={this.onChange}>
                                <option key="0" value="0">Todos</option>
                                <option key="1" value="1">Activo</option>
                                <option key="2" value="2">No activo</option>
                            </Form.Control>
                            &nbsp;
                            <FormControl size="sm" type="text" placeholder="Buscar" name="nombre" onChange={this.onChange} className="mr-sm-2" />
                            <Button size="sm" variant="success" onClick={this.onBntBuscarClick}>Buscar</Button>
                        </Form>
                    </Navbar.Collapse>
                </Navbar>
                <div style={{ height: 4 }}></div>
                    <AlumnosTable alumnos={this.state.alumnos} height={500} width={990} onRowClick={this.cargarAlumno} />
                </div>
                <span>Alumnos : <b>{this.state.conteo}</b> </span>
                <AlumnoForm visible={this.state.formVisible} alumno={this.state.alumno} onClose={this.onFormClose} matricula={this.state.matricula} guardar={this.guardarAlumno} urlws={this.props.urlws} doyang={this.state.doyang}/>
            </Container>
        );
    }
}