import React from 'react';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Form from 'react-bootstrap/Form';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Spinner from 'react-bootstrap/Spinner';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

export default class ExamenesAsig extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            cargando:true,
            formVisible: false,
            doyang:-1,
            doyangs:[],
            examenes: [],
            alumnos:[]
        };
        this.cargarCatalogos();
    }
    cargarCatalogos = async() =>{
        let res = await fetch(`${this.props.urlws}/doyang/listaUsuario/${this.props.usuario.id}`);
        const du = this.props.usuario.doyang;
        const dys = await res.json();
        const doyangs = dys;// .filter((d)=> du === 0 || du === d.Id);
        //const doyang = (du > 0) ? du : -1;
        const doyang = -1;
        this.setState({ doyangs,doyang,cargando:false });
    }
    cargarExamenes = async () => {
        this.setState({cargando:true});
        const url = `${this.props.urlws}/examen/lista/1?fecIni=2000-01-01&fecFin=2050-01-01`;
        const resp = await fetch(url);
        const examenes = await resp.json();
        this.setState({cargando:false, examenes });
    }
    cargarAlumnos = async () => {
        this.setState({cargando:true});
        const url = `${this.props.urlws}/examen/porAsignar/${this.state.doyang}`;
        const resp = await fetch(url);
        const alumnos = await resp.json();
        this.setState({cargando:false, alumnos });
    }
    asignar = (alu) => {
        const MySwal = withReactContent(Swal)
        MySwal.fire({
            title: 'Confirme',
            text: "¿ Asignar " + alu.Nombre +  " ?",
            type: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'No',
            confirmButtonText: 'Si'
          }).then((result) => {
            if (result.value) {
                this.asignaB(alu);
            }
          })        
    }
    asignaB = async(alu) =>{
        const url = this.props.urlws + `/examen/asignar?doyang=${this.state.doyang}&pago=${alu.Numero}&examen=${this.state.examen}`;
        this.setState({cargando:true});
        const resp = await fetch(url);
        const res = await resp.text();
        this.setState({cargando:false});
        if(res === "ok"){
            Swal.fire('Atencion','Datos guardados','success');
            let alumnos = this.state.alumnos;
            for(let k = 0; k < alumnos.length; k++)
               if(alumnos[k].Alumno === alu.Alumno)
                   alumnos[k].A = true;
            this.setState({alumnos});
        }
        else
            Swal.fire('Atencion',res,'error');
    }

    onSubmit = (e) => {
        e.preventDefault();
        this.cargarAlumnos();
    }
    onChange = (e) => {
        const campo = e.target.name;
        this.setState({ [campo] : e.target.value},() =>{
            if(campo === "doyang")
                this.cargarExamenes(); 
        });
    }
    onBntBuscarClick = () =>{
        this.cargarAlumnos();
    }    
    render(){
        let lugar = '';
        let sinodal = '';
        for(let k = 0; k < this.state.examenes.length; k++)
           if(this.state.examenes[k].Numero === this.state.examen){
               lugar = this.state.examenes[k].Lugar;
               sinodal = this.state.examenes[k].Sinodal;
           }
        return(
            <Container >
                {this.state.cargando ?
                <div className="loader">
                    <Spinner animation="border" variant="primary"/>
                </div>
                : 
                null
                }
            <div>
            <Navbar bg="light" expand="lg">
                <Navbar.Brand size="sm" href="#home">&nbsp;</Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="mr-auto" size="sm">
                    </Nav>
                    <Form inline onSubmit={this.onSubmit} >
                            <Form.Label size="sm">Doyang</Form.Label>&nbsp;
                            <Form.Control size="sm" as="select" name="doyang" value={this.state.doyang} onChange={this.onChange}>
                                <option key={-1} value={-1}>Especifique</option>    
                                {this.state.doyangs.map((i,k) =>(
                                    <option key={i.Id} value={i.Id}>{i.Nombre}</option>    
                                ))}
                            </Form.Control>
                            <Form.Label size="sm">Examen</Form.Label>&nbsp;
                            <Form.Control size="sm" as="select" name="examen" value={this.state.examen} onChange={this.onChange}>
                                <option key={-1} value={-1}>Especifique</option>    
                                {this.state.examenes.map((i,k) =>(
                                    <option key={i.Numero} value={i.Numero}>{i.Fecha} - {i.Profesor}</option>
                                ))}
                            </Form.Control>
                        &nbsp;&nbsp;
                        <Button size="sm" variant="success" onClick={this.onBntBuscarClick}>iniciar</Button>
                    </Form>
                </Navbar.Collapse>
            </Navbar>
            <div style={{ height: 4 }}></div>
            <div className="row">
                <div className="col">
                    <span>Sinodal</span>
                    &nbsp;
                    <span style={{fontWeight:'bold'}}>{sinodal}</span>
                </div>
                <div className="col">
                    <span>Lugar</span>
                    &nbsp;
                    <span style={{fontWeight:'bold'}}>{lugar}</span>
                </div>
            </div>
            <div style={{ height: 4 }}></div>
            <AlumnosTable alumnos={this.state.alumnos} height={500} width={990} onRowClick={this.asignar} />
            </div>
        </Container>
        );
    }
}
class AlumnosTable extends React.Component {
    onRowClick = (alu) => {
        this.props.onRowClick(alu);
    }
    render() {
        let { alumnos } = this.props;
        return (
            <div style={{ height: this.props.height, width:this.props.width, overflow: 'auto' }}>
            <Table striped bordered hover size="sm">
                <thead>
                    <tr>
                        <th style={{width:100}}>Pago</th>
                        <th>Tipo</th>
                        <th>Clave</th>
                        <th>Nombre</th>
                        <th></th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {alumnos.map((alu, i) => (
                        <AlumnoRow key={i} alumno={alu} onRowClick={this.onRowClick} />
                    ))}
                </tbody>
                </Table>
            </div>
        );
    }
}

class AlumnoRow extends React.Component{
    onClick = (e) =>{
        this.props.onRowClick(this.props.alumno);
    }
    render(){
        let {alumno} = this.props;
        return (
        <tr>
            <td>{alumno.Numero}</td>
            <td>{alumno.Tipo}</td>
            <td>{alumno.Clave}</td>
            <td>{alumno.Nombre}</td>
            <td><Form.Check inline type="checkbox" checked={alumno.A}/></td>
            <td>
                <Button variant="primary" size="sm" onClick={this.onClick}>
                    Asignar
                </Button>
            </td>
        </tr>
        );
    }
}
