import React from 'react';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Form from 'react-bootstrap/Form';
import Table from 'react-bootstrap/Table';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Spinner from 'react-bootstrap/Spinner';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

export default class Usuarios extends React.Component {
    usuInicial = {Id:0,Doyang:0,Admin:false,Email:"",Pwd:"",Nombre:"",NombreDoyang:"", PwdB : "", Activo:true, Doyangs:''}

    constructor(props) {
        super(props);
        this.state = {
            cargando:true,
            formVisible: false,
            id: 0,
            doyang:999999,
            nombre:'',
            usuario:{},
            doyangs:[],
            usuarios: [],
            doyangsSel:[]
        };
        this.cargarCatalogos();
        this.cargarUsuarios();
    }
    onFormClose = () => {
        this.setState({
            formVisible: false
        });
    }
    cargarCatalogos = async() =>{
        let res = await fetch(`${this.props.urlws}/doyang/listaUsuario/${this.props.usuario.id}`);
        const du = this.props.usuario.doyang;
        const dys = await res.json();
        const doyangsSel = dys.map((e,i)=> {
            return {
                Id : e.Id,
                Nombre : e.Nombre,
                Sel: false
            }
        });
        const doyangs = dys;//.filter((d)=> du === 0 || du === d.Id);
        const doyang = (du > 0) ? du : -1;
        this.setState({ doyangs, doyang, doyangsSel });
    }
    cargarUsuarios = async () => {
        this.setState({cargando:true});
        let d = this.state.doyang;
        if(d === -1)
           d = 999999;
        const url = this.props.urlws + "/usuario/wslista/" + d;
        const resp = await fetch(url);
        let usuarios = await resp.json();
         this.setState({cargando:false, usuarios });
    }
    onSubmit = (e) => {
        e.preventDefault();
        this.cargarUsuarios();
    }
    onChange = (e) => {
        this.setState({ [e.target.name] : e.target.value});
    }
    onBntBuscarClick = () =>{
        this.cargarUsuarios();
    }    
    cargarUsuario = async (cve) => {
        this.setState({cargando:true});
        const url = this.props.urlws + "/usuario/wscargar/" + cve;
        const resp = await fetch(url);
        const usuario = await resp.json();
        let doyangsSel = this.state.doyangsSel;
        usuario.PwdB = usuario.Pwd;
        const dys = usuario.Doyangs.split(",");
        for(let k = 0; k < doyangsSel.length; k++){
            doyangsSel[k].Sel = Number(doyangsSel[k].Id) === usuario.Doyang;
        }
        for(let k = 0; k < dys.length; k++){
            for(let j = 0; j < doyangsSel.length; j++){
                if(Number(doyangsSel[j].Id) === Number(dys[k]))
                    doyangsSel[j].Sel = true;
            }
        }

        this.setState({
            cargando:false,
            formVisible: true,
            id: cve,
            doyangsSel,
            usuario
        });
    }
    guardarUsuario = async(usuario) =>{
        const url = this.props.urlws + "/usuario/wsguardar";
        this.setState({cargando:true, usuario});
        if(usuario.Doyang === 0)
            usuario.Doyang = this.state.doyang;
        const json = JSON.stringify(usuario);
        const resp = await fetch(url,{
            method: 'POST', // or 'PUT'
            body: json // data can be `string` or {object}!
          });
        const res = await resp.text();
        this.setState({cargando:false});
        if(res === "ok"){
            Swal.fire('Atencion','Datos guardados','success');
            this.onFormClose();
            this.cargarUsuarios();
        }
        else
            Swal.fire('Atencion',res,'error');
    }
    nuevoUsuario = () => {
        if(this.state.doyang < 0){
            Swal.fire('Atencion','Especifique el Doyang','error');
            return;
        }
        let esc = {...this.usuInicial};
        this.setState({
            formVisible: true,
            id : 0,
            usuario:esc
        });
    }
    render() {
        const estilo = { display: this.state.formVisible ? 'none' : 'inline' };
        return (
            <Container >
                {this.state.cargando ?
                <div className="loader">
                    <Spinner animation="border" variant="primary"/>
                </div>
                : 
                null
                }
                <div style={estilo}>
                <Navbar bg="light" expand="lg">
                    <Navbar.Brand size="sm" href="#home">Usuarios</Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="mr-auto" size="sm">
                            <Button size="sm" variant="success" onClick={this.nuevoUsuario}>+</Button>
                        </Nav>
                        <Form inline onSubmit={this.onSubmit} >
                                <Form.Label size="sm">Doyang</Form.Label>&nbsp;
                                <Form.Control size="sm" as="select" name="doyang" value={this.state.doyang} onChange={this.onChange}>
                                    <option key={-1} value={999999}>Especifique</option>    
                                    {this.state.doyangs.map((i,k) =>(
                                        <option key={i.Id} value={i.Id}>{i.Nombre}</option>    
                                    ))}
                                    {this.props.usuario.doyang === 0
                                    ?
                                    <React.Fragment>
                                    <option value={0}>DIRECCION GENERAL</option>
                                    <option value={-2}>TODOS</option>
                                    </React.Fragment>
                                    :null
                                    }
                                </Form.Control>
                            &nbsp;
                            <Button size="sm" variant="success" onClick={this.onBntBuscarClick}>Buscar</Button>
                        </Form>
                    </Navbar.Collapse>
                </Navbar>
                <div style={{ height: 4 }}></div>
                    <UsuarioTable usuarios={this.state.usuarios} height={500} width={990} onRowClick={this.cargarUsuario} />
                </div>
                <UsuarioForm visible={this.state.formVisible} usuario={this.state.usuario} onClose={this.onFormClose} clave={this.state.id} guardar={this.guardarUsuario} doyangs={this.state.doyangsSel}/>
            </Container>
        );
    }
}

class UsuarioTable extends React.Component {
    onRowClick = (cve) => {
        this.props.onRowClick(cve);
    }
    render() {
        let { usuarios } = this.props;
        return (
            <div style={{ height: this.props.height, width:this.props.width, overflow: 'auto' }}>
            <Table striped bordered hover size="sm">
                <thead>
                    <tr>
                        <th style={{width:100}}>Clave</th>
                        <th>Nombre</th>
                        <th>Doyang</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {usuarios.map((esc, i) => (
                        <UsuarioRow key={i} usuario={esc} onRowClick={this.onRowClick} />
                    ))}
                </tbody>
                </Table>
            </div>
        );
    }
}

class UsuarioRow extends React.Component{
    onClick = (e) =>{
        this.props.onRowClick(this.props.usuario.Id);
    }
    render(){
        let {usuario} = this.props;
        return (
        <tr>
            <td>{usuario.Id}</td>
            <td>{usuario.Nombre}</td>
            <td>{usuario.Doyang}</td>
            <td>
                <Button variant="primary" size="sm" onClick={this.onClick}>
                    <img src="/imagenes/pencil.png" alt="editar" title="editar"/>
                </Button>
            </td>
        </tr>
        );
    }
}

class UsuarioForm extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            //...this.props.alumno,
            usuario:this.props.usuario,
            doyangs:this.props.doyangs
        }
    }
    componentWillReceiveProps(newProps){
        this.setState({
            usuario:newProps.usuario,
            doyangs:newProps.doyangs
        });
    }
    onChange = (e) => {
        const esc = {
            ...this.state.usuario,
            [e.target.name] : e.target.value
        };
        this.setState({usuario:esc});
    }
    onChecked = (e) => {
        const alu = {
            ...this.state.usuario,
            [e.target.name] : e.target.checked
        };
        this.setState({usuario:alu});
    }    
    onDoyangChecked = (e) => {
        const doy = Number(e.target.name.substring(3,5));
        let doys = this.state.doyangs;
        doys[doy].Sel = e.target.checked;
        this.setState({doyangs:doys});
    }    
    guardar = () =>{
        if( this.state.usuario.Pwd === undefined || this.state.usuario.Pwd === "" || this.state.usuario.Pwd !== this.state.usuario.PwdB){
            Swal.fire('Atencion','Especifique y confirme la contraseña','error');
            return;
        }
        let doyangs = "";
        let sepa = "";
        for(let k = 0; k < this.state.doyangs.length; k++)
           if(this.state.doyangs[k].Sel){
               doyangs += sepa + this.state.doyangs[k].Id;
               sepa = ",";
           }
        this.state.usuario.Doyangs = doyangs;
        const MySwal = withReactContent(Swal)
        MySwal.fire({
            title: 'Confirme',
            text: "¿ Desea guardar estos datos ?",
            type: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'No',
            confirmButtonText: 'Si'
          }).then((result) => {
            if (result.value) {
                this.props.guardar(this.state.usuario);
            }
          })        
    }
    render() {
        const estilo = { display: this.props.visible ? 'inline' : 'none' };
        if(this.props.usuario === undefined)
            return null;
        if(Object.keys(this.props.usuario).length === 0)
            return null;
        return (
            <Container style={estilo}>
                <Form>
                    <div className="row">
                    <div className="col">
                        <Form.Row>
                            <Form.Group as={Col} md="2">
                                <Form.Label>Id</Form.Label>
                                <Form.Control size="sm" type="text" name="Id" value={this.state.usuario.Id} />
                            </Form.Group>
                            <Form.Group as={Col}>
                                <Form.Label>Nombre</Form.Label>
                                <Form.Control size="sm" type="text" name="Nombre" value={this.state.usuario.Nombre} onChange={this.onChange} />
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col}>
                                <Form.Label>Email</Form.Label>
                                <Form.Control size="sm" type="text" name="Email" value={this.state.usuario.Email} onChange={this.onChange} />
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col}>
                                <Form.Label>Contraseña</Form.Label>
                                <Form.Control size="sm" type="password" name="Pwd" value={this.state.usuario.Pwd} onChange={this.onChange} />
                            </Form.Group>
                            <Form.Group as={Col}>
                                <Form.Label>Confirmar</Form.Label>
                                <Form.Control size="sm" type="password" name="PwdB" value={this.state.usuario.PwdB} onChange={this.onChange} />
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col}>
                                <br/>
                                <Form.Check inline label="Administrador" type="checkbox" name="Admin" checked={this.state.usuario.Admin} onChange={this.onChecked} />
                            </Form.Group>
                            <Form.Group as={Col}>
                                <br/>
                                <Form.Check inline label="Activo" type="checkbox" name="Activo" checked={this.state.usuario.Activo} onChange={this.onChecked} />
                            </Form.Group>
                        </Form.Row>
                            <Form.Row>
                            </Form.Row>
                    </div>
                    <div className="col">
                    <Table striped bordered hover size="sm">
                                <thead>
                                    <tr>
                                        <th>Doyang</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.doyangs.map((esc, i) => (
                                        <tr>
                                            <td>{esc.Nombre}</td>
                                            <td>
                                                <Form.Check inline  type="checkbox" name={"doy" + i} checked={esc.Sel} onChange={this.onDoyangChecked} />
                                            {esc.Sel}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                            </div>
                    </div>
                </Form>
                <br />
                                <Button variant="primary" type="submit" onClick={this.guardar}>Aceptar</Button>
                                &nbsp;&nbsp;
                                <Button variant="danger" onClick={this.props.onClose}>Cancelar</Button>
 
            </Container>
        );
    }
}