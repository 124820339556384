import React from 'react';
import Button from 'react-bootstrap/Button';

export default class AlumnoRow extends React.Component{
    onClick = (e) =>{
        this.props.onRowClick(this.props.alumno.Clave,this.props.alumno.Id);
    }
    render(){
        let {alumno} = this.props;
        return (
        <tr>
            <td>{alumno.Clave}</td>
            <td>{alumno.Nombre}</td>
            <td>{alumno.Direccion}</td>
            <td>{alumno.Grado}</td>
            <td>
                <Button variant="primary" size="sm" onClick={this.onClick}>
                    <img src="/imagenes/pencil.png" alt="editar" title="editar"/>
                </Button>
            </td>
        </tr>
        );
    }
}