import React from 'react';
import Table from 'react-bootstrap/Table';
import AlumnoRow from './AlumnoRow';

export default class AlumnosTable extends React.Component {
    onRowClick = (mat,id) => {
        this.props.onRowClick(mat,id);
    }
    render() {
        let { alumnos } = this.props;
        return (
            <div style={{ height: this.props.height, width:this.props.width, overflow: 'auto' }}>
            <Table striped bordered hover size="sm">
                <thead>
                    <tr>
                        <th>Clave</th>
                        <th>Nombre</th>
                        <th>Direccion</th>
                        <th>Grado</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {alumnos.map((alu, i) => (
                        <AlumnoRow key={i} alumno={alu} onRowClick={this.onRowClick} />
                    ))}
                </tbody>
                </Table>
            </div>
        );
    }
}