import React from 'react';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Form from 'react-bootstrap/Form';
import Table from 'react-bootstrap/Table';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Spinner from 'react-bootstrap/Spinner';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

export default class Examenes extends React.Component {
    exaInicial = {Doyang:0, Numero:0, Fecha:new Date().yyyymmdd('-'), Profesor:0, Sinodal:'', Status:'A', Lugar:''}

    constructor(props) {
        super(props);
        let fec = new Date();
        fec.addDays(-7);
        let fecIni = fec.yyyymmdd('-');
        let fecFin = new Date().yyyymmdd('-');

        this.state = {
            cargando:true,
            formVisible: false,
            numero: 0,
            doyang:0,
            examen:{},
            doyangs:[],
            profesores:[],
            examenes: [],
            fechaIni:fecIni,
            fechaFin:fecFin
        };
        this.cargarCatalogos();
    }
    onFormClose = () => {
        this.setState({
            formVisible: false
        });
    }
    cargarCatalogos = async() =>{
        let res = await fetch(`${this.props.urlws}/doyang/listaUsuario/${this.props.usuario.id}`);
        const du = this.props.usuario.doyang;
        const dys = await res.json();
        const doyangs = dys;// .filter((d)=> du === 0 || du === d.Id);
        const doyang = 0; // (du > 0) ? du : -1;
        this.setState({ doyangs,doyang,cargando:false });
    }
    cargarProfesores = async () => {
        this.setState({cargando:true});
        const url = this.props.urlws + "/profesor/lista/" + this.state.doyang;
        const resp = await fetch(url);
        const profesores = await resp.json();
        this.setState({cargando:false, profesores });
    }
    cargarExamenes = async () => {
        this.setState({cargando:true});
        const url = `${this.props.urlws}/examen/lista/${this.state.doyang}?fecIni=${this.state.fechaIni}&fecFin=${this.state.fechaFin}`;
        const resp = await fetch(url);
        const examenes = await resp.json();
        this.setState({cargando:false, examenes });
    }
    onSubmit = (e) => {
        e.preventDefault();
        this.cargarExamenes();
    }
    onChange = (e) => {
        const campo = e.target.name;
        this.setState({ [campo] : e.target.value},() =>{
            if(campo === "doyang")
                this.cargarProfesores(); 
        });
    }
    onBntBuscarClick = () =>{
        this.cargarExamenes();
    }    
    cargarExamen = async (cve) => {
        this.setState({cargando:true});
        const url = this.props.urlws + "/examen/cargar/" + cve + "?doyang=" + this.state.doyang;
        const resp = await fetch(url);
        const examen = await resp.json();
        this.setState({
            cargando:false,
            formVisible: true,
            numero: cve,
            examen
        });
    }
    guardarExamen = async(examen) =>{
        const url = this.props.urlws + "/examen/guardar";
        this.setState({cargando:true, examen});
        if(examen.Doyang === 0)
            examen.Doyang = this.state.doyang;
        const json = JSON.stringify(examen);
        const resp = await fetch(url,{
            method: 'POST', // or 'PUT'
            body: json // data can be `string` or {object}!
          });
        const res = await resp.text();
        this.setState({cargando:false});
        if(res === "ok"){
            Swal.fire('Atencion','Datos guardados','success');
            this.onFormClose();
            this.cargarExamenes();
        }
        else
            Swal.fire('Atencion',res,'error');
    }
    nuevoExamen = () => {
        let esc = {...this.exaInicial};
        this.setState({
            formVisible: true,
            id : 0,
            examen:esc
        });
    }
    render() {
        const estilo = { display: this.state.formVisible ? 'none' : 'inline' };
        return (
            <Container >
                {this.state.cargando ?
                <div className="loader">
                    <Spinner animation="border" variant="primary"/>
                </div>
                : 
                null
                }
                <div style={estilo}>
                <Navbar bg="light" expand="lg">
                    <Navbar.Brand size="sm" href="#home">&nbsp;</Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="mr-auto" size="sm">
                        </Nav>
                        <Form inline onSubmit={this.onSubmit} >
                                <Form.Label>&nbsp;del&nbsp; </Form.Label>
                                <Form.Control size="sm" type="date" name="fechaIni" value={this.state.fechaIni} onChange={this.onChange} />
                                <Form.Label>&nbsp;al&nbsp; </Form.Label>
                                <Form.Control size="sm" type="date" name="fechaFin" value={this.state.fechaFin} onChange={this.onChange} />
                            &nbsp;
                            <Button size="sm" variant="success" onClick={this.onBntBuscarClick}>Buscar</Button>
                            &nbsp;
                            <Button size="sm" variant="success" onClick={this.nuevoExamen}>+</Button>
                        </Form>
                    </Navbar.Collapse>
                </Navbar>
                <div style={{ height: 4 }}></div>
                    <ExamenTable examenes={this.state.examenes} height={500} width={990} onRowClick={this.cargarExamen} />
                </div>
                <ExamenForm visible={this.state.formVisible} examen={this.state.examen} profesores={this.state.profesores} onClose={this.onFormClose} clave={this.state.numero} guardar={this.guardarExamen}/>
            </Container>
        );
    }
}

class ExamenTable extends React.Component {
    onRowClick = (cve) => {
        this.props.onRowClick(cve);
    }
    render() {
        let { examenes } = this.props;
        return (
            <div style={{ height: this.props.height, width:this.props.width, overflow: 'auto' }}>
            <Table striped bordered hover size="sm">
                <thead>
                    <tr>
                        <th style={{width:100}}>Numero</th>
                        <th>Fecha</th>
                        <th>Profesor</th>
                        <th>Sinodal</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {examenes.map((esc, i) => (
                        <ExamenRow key={i} examen={esc} onRowClick={this.onRowClick} />
                    ))}
                </tbody>
                </Table>
            </div>
        );
    }
}

class ExamenRow extends React.Component{
    onClick = (e) =>{
        this.props.onRowClick(this.props.examen.Numero);
    }
    render(){
        let {examen} = this.props;
        return (
        <tr>
            <td>{examen.Numero}</td>
            <td>{examen.Fecha}</td>
            <td>{examen.Profesor}</td>
            <td>{examen.Sinodal}</td>
            <td>
                <Button variant="primary" size="sm" onClick={this.onClick}>
                    <img src="/imagenes/pencil.png" alt="editar" title="editar"/>
                </Button>
            </td>
        </tr>
        );
    }
}

class ExamenForm extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            examen:this.props.examen,
        }
    }
    componentWillReceiveProps(newProps){
        this.setState({
            examen:newProps.examen
        });
    }
    onChange = (e) => {
        const esc = {
            ...this.state.examen,
            [e.target.name] : e.target.value
        };
        this.setState({examen:esc});
    }
    guardar = () =>{
        const MySwal = withReactContent(Swal)
        MySwal.fire({
            title: 'Confirme',
            text: "¿ Desea guardar estos datos ?",
            type: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'No',
            confirmButtonText: 'Si'
          }).then((result) => {
            if (result.value) {
                this.props.guardar(this.state.examen);
            }
          })        
    }
    render() {
        const estilo = { display: this.props.visible ? 'inline' : 'none' };
        if(this.props.examen === undefined)
            return null;
        if(Object.keys(this.props.examen).length === 0)
            return null;
        return (
            <Container style={estilo}>
                <Form>
                    <div>
                        <Form.Row>
                            <Form.Group as={Col} md="6">
                                <Form.Label>Numero</Form.Label>
                                <Form.Control size="sm" type="text" name="Numero" value={this.state.examen.Numero} />
                            </Form.Group>
                            <Form.Group as={Col} md="6">
                                <Form.Label>Fecha</Form.Label>
                                <Form.Control size="sm" type="date" name="Fecha" value={this.state.examen.Fecha} onChange={this.onChange} />
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                        <Form.Group as={Col} md="12">
                                <Form.Label>Lugar</Form.Label>
                                <Form.Control size="sm" type="text" name="Lugar" value={this.state.examen.Lugar} onChange={this.onChange} />
                            </Form.Group>
                        </Form.Row>
                    </div>
                </Form>
                <br />
                <Button variant="primary" type="submit" onClick={this.guardar}>Aceptar</Button>
                {'  '}
                <Button variant="danger" onClick={this.props.onClose}>Cancelar</Button>
            </Container>
        );
    }
}