import React from 'react';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Opciones from './Opciones';

export default class SideBarMenu extends React.Component {
    render() {
        return (
            <div id="sidebar-menu">
                <Navbar bg="light" className="flex-column" style={{height:'100%'}}>
                    <Navbar.Brand></Navbar.Brand>
                    <Nav defaultActiveKey="/home" className="flex-column">
                        <Opciones opcionSeleccionada={this.props.opcionSeleccionada} usuario={this.props.usuario}/>
                    </Nav>
                </Navbar>
            </div>
        );
    }
}